<!-- 查看预置位历史记录 -->
<template>
  <!-- <div>查看预置位历史记录</div> -->
  <div>
    <a-table
      size="default"
      bordered
      :columns="columns"
      :data-source="dataSource"
      :pagination="false"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ (current - 1) * pageSize + index + 1 }}
      </span>
      <span slot="dpTime" slot-scope="text">
        {{ $Format(text) }}
      </span>
      <span slot="dp_file" slot-scope="text, record" class="info_out">
        <span class="info" @click="showInfo(text, record.dpDImei)">
          <div class="actions">
            <div class="icon_out">
              <img
                :src="pathUrl + text"
                width="100%"
                height="100%"
                class="imgname"
                href="javascript:;"
              />
              <a-icon type="zoom-in" class="icon" />
            </div>
          </div>
        </span>
      </span>
    </a-table>
    <a-pagination
      show-size-changer
      v-model="current"
      :total="pageTotal"
      @showSizeChange="onShowSizeChange"
      @change="pageNumberChange"
      :pageSizeOptions="sizeList"
      :defaultPageSize="defaultPageSize"
      style="margin: 20px 0; text-align: right"
    />
    <a-modal
      width="1080px"
      :footer="null"
      :visible="visibleImg"
      @cancel="imgCancel"
    >
      <div slot="title" style="width: 100%">（{{ ImgName }}）</div>
      <div v-if="Image !== ''" style="width: 100%">
        <img :src="Image" width="100%" href="javascript:;" />
      </div>
      <div v-else>暂无图片</div>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    dataIndex: "serial",
    scopedSlots: { customRender: "serial" },
    key: "serial",
    width: "10px",
    align: "center",
  },

  {
    title: "设备IMEI号",
    key: "dpDImei",
    dataIndex: "dpDImei",
    width: "70px",
    align: "center",
  },
  {
    title: "预置时间",
    key: "dpTime",
    dataIndex: "dpTime",
    width: "60px",
    scopedSlots: { customRender: "dpTime" },
  },
  {
    title: "操作人",
    key: "dpWName",
    dataIndex: "dpWName",
    width: "60px",
  },
  {
    title: "预置快照",
    key: "dpFile",
    dataIndex: "dpFile",
    width: "70px",
    // slots: { title: "dp_file" },
    scopedSlots: { customRender: "dp_file" },
  },
];
import { urlConfig } from "@/config/config";
import { GethistoryList } from "@/api/video";
import { STable } from "@/components";
export default {
  name: "SiteHistoryRecord",
  components: { STable },
  props: {
    recordwsid: {
      default: "",
    },
  },
  data() {
    this.columns = columns;
    return {
      // 查询参数
      queryParam: {},
      data: [],
      // columns,
      // 人脸信息图片地址
      pathUrl:
        urlConfig.baseUrl + "/files/picture/snapshot/snapshotpicture?filePath=",
      // 查看图片
      visibleImg: false,
      ImgName: "",
      Image: "",
      pageSize: 5,
      current: 1,
      pageTotal: 50,
      sizeList: ["5", "10", "20", "30", "40"], //一页能显示条数
      dataSource: [],
      defaultPageSize: 5,
    };
  },
  created() {
    this.queryParam.ws_id = this.recordwsid;
    // this.siteName = this.$route.query.name;
    this.fetchAttend();
  },
  watch: {},
  methods: {
    fetchAttend() {
      this.tableLoading = true;
      let params = {
        dp_ws_id: this.recordwsid,
      };
      GethistoryList(params).then((res) => {
        this.data = res.data;
        this.getShowSource();
      });
    },
    showInfo(url, dpDImei) {
      this.visibleImg = true;
      this.Image = "";
      this.ImgName = dpDImei;
      let that = this;
      that.Image = this.pathUrl + url;
    },
    imgCancel() {
      this.visibleImg = false;
    },
    //  分页显示条数的变化
    onShowSizeChange(current, pageSize) {
      console.log(current, pageSize);
      this.current = current;
      this.pageSize = pageSize;
      this.getShowSource();
    },
    //分页页数的改变
    pageNumberChange(current, size) {
      this.current = current;
      this.pageSize = size;
      this.getShowSource();
    },
    // 实现分页效果
    getShowSource() {
      const data = this.data;
      // console.log(data);
      //后端返回的全部数据
      let keyValue = 0;
      this.pageTotal = data.length;
      for (let i = 0; i < this.total; i++) {
        keyValue = keyValue + 1;
        let key = { key: keyValue };
        data[i] = Object.assign(data[i], key);
      }
      let start = this.pageSize * this.current - this.pageSize;
      let end = this.pageSize * this.current;
      let dataSource = data.slice(start, end);
      console.log(data);
      this.dataSource = dataSource;
      this.$set(this.dataSource, [], dataSource);
    },
  },
};
</script>
<style lang="less" scoped>
.attend-header {
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
}
.events {
  color: #fff;
  padding: 0 5px;
  background-color: #1890ff;
}
// 鼠标悬停蒙层
.icon_out {
  width: 100%;
  height: 80px;
  position: relative;
  display: flex;
  // background-color: pink;
}
.icon {
  font-size: 25px;
  left: 43%;
  top: 40%;
  position: absolute;
  margin: auto;
  display: none;
}
.info {
  &:before {
    content: " ";
    position: absolute;
    z-index: 1;
    background-color: fade(rgba(3, 3, 3, 0.966), 25%);
    // transition: all 0.3s;

    width: 100%;
    // width: 21%;
    // width: 220px;
    height: 80px;
    opacity: 0;
  }
}
.actions {
  width: 100%;

  z-index: 10;
  opacity: 1;
}
.info_out {
  position: relative;
  cursor: pointer;
}
.info:hover {
  &:before {
    opacity: 1;
    position: absolute;
  }
  .actions {
    opacity: 1;
  }
  .icon {
    display: flex;
    color: white;
    z-index: 2;
  }
}
.info:hover + .actions,
.actions:hover {
  opacity: 1;
}
</style>
