<template>
  <div class="nocase_out">
    <div class="nocase">
      <p>暂无样板工地播放</p>
      <a-button type="primary" @click="goYBGD"> 去设置样板工地 </a-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "SampleSite",
  methods: {
    goYBGD() {
      this.$router.push({
        path: "/SampleSite",
        name: "SampleSite",
      });
    },
  },
};
</script>

<style>
.nocase_out {
  width: 100%;
  height: 100%;
  position: relative;
}
.nocase {
  position: absolute;
  left: 45%;
  margin-top: 300px;
  font-size: 30px;
  color: aliceblue;
  font-weight: 500;
}
</style>
