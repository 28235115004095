<template>
  <div style="display: inline-block; margin-left: 12px">
    <span @click="showModal" class="setBtn">
      <i class="iconfont icon-shezhi" />设置</span
    >
    <a-modal
      class="tips-modal"
      title="设置案场"
      v-model="visible"
      on-ok="确定"
      cancelText="重置"
      :visible="visible"
    >
      <template slot="footer">
        <a-button key="back" @click="handleCancel"> 重置 </a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="loading"
          @click="handleOk"
        >
          确定
        </a-button>
      </template>
      <span>分公司：</span>
      <!-- 展开搜索条件 -->
      <a-select
        label-in-value
        v-model="queryParam"
        style="width: 120px"
        @change="handleChange"
      >
        <a-select-option
          v-for="item in selectOption"
          :key="item.value"
          :value="item.value"
        >
          {{ item.label }}
        </a-select-option>
      </a-select>
      <!-- 播放有效时间 -->
      <div class="radio_out" v-show="true">
        <span>播放有效时间：</span>
        <a-radio-group name="radioGroup" :default-value="1">
          <a-radio :value="1" @change="onChange">推荐</a-radio>
          <a-radio :value="2" @change="onChange2"> 自定义播放时间段 </a-radio>
        </a-radio-group>
        <div class="radio_out">
          <!-- 推荐播放时间 -->
          <p v-show="show">
            往后推
            <a-input-number
              :min="1"
              :max="12"
              v-model="IptValue"
              :formatter="inputChange"
              :parser="inputChange"
              class="TimeIpt"
            ></a-input-number>
            小时，结束播放
          </p>
        </div>
        <div>
          <!--自定义播放时间 -->
          <div v-show="show2">
            <div class="select-range-time">
              <a-row style="line-height: 40px, width: 400px">
                <a-col :span="7">当前时间：</a-col>
                <a-col :span="6" style="text-align: center">
                  <a-time-picker
                    style="width: 90px"
                    size="small"
                    disabled
                    :defaultValue="moment(startTime, 'HH:mm')"
                    @change="
                      (val, dateStrings) =>
                        changeTime(val, dateStrings, 'startTime')
                    "
                    format="HH:mm"
                  />
                </a-col>
              </a-row>
              <a-row style="line-height: 40px, width: 400px">
                <a-col :span="7">结束时间:</a-col>
                <a-col :span="6" style="text-align: center">
                  <a-time-picker
                    style="width: 90px"
                    size="small"
                    :disabledHours="getDisabledHours"
                    :disabledMinutes="getDisabledMinutes"
                    :defaultValue="moment(endTime, 'HH:mm')"
                    @change="
                      (val, dateStrings) =>
                        changeTime(val, dateStrings, 'endTime')
                    "
                    format="HH:mm"
                  />
                </a-col>
              </a-row>
            </div>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
// 查询分公司列表
import { GetCompanies } from "@/api/device";
import { SelectOptions } from "@/config/SelectOptions.js";
import { SSearch } from "@/components";
import { StopSiteVideo } from "@/api/video";
import LiveBroadcast from "../patrol/LiveBroadcast.vue";
import moment from "moment";

export default {
  name: "CaseSet",
  components: {
    SSearch,
    GetCompanies,
    SelectOptions,
    LiveBroadcast,
  },

  data() {
    return {
      loading: false,
      visible: false,
      checkedList: [],
      indeterminate: true,
      checkAll: false,
      queryParam: [],
      show: true,
      show2: false,
      selectOption: [],
      bc_id: "",
      startTime: "00:00",
      endTime: "00:00",
      TimeOut: "",
      IptValue: 4,
      //
      ws_id: "",
      video: {
        stream_id: "",
        push_stream_id: "",
        com_only: "",
      },
    };
  },
  created() {
    this.GetCompanies();
    this.GetCompaniesValue();
    this.getNowTime();
  },
  mounted() {},
  methods: {
    moment,
    showModal() {
      this.visible = true;
    },
    // 分公司下拉框数据
    GetCompanies() {
      GetCompanies().then((res) => {
        this.selectOption.push({ value: "0", label: "全部" });
        for (let index = 0; index < res.data.length; index++) {
          const element = res.data[index];
          this.selectOption.push({
            value: element.bc_id,
            label: element.bc_name,
          });
        }

        // console.log(this.selectOption);
      });
    },
    // 选择的分公司下拉框的数据
    GetCompaniesValue() {
      GetCompanies().then((res) => {
        // console.log(res);
        this.queryParam.length = 0;
        this.queryParam.push({ value: "", label: "全部" });
        for (let index = 0; index < res.data.length; index++) {
          const element = res.data[index];
          this.queryParam.push({
            value: element.bc_id,
            label: element.bc_name,
          });
        }
        // console.log(this.queryParam);
      });
    },

    // 查询方法
    search() {
      // 获取下拉框里分公司id
      // const params = {
      //   bc_id: this.queryParam.key,
      // };
      let bc_id = this.queryParam.key;
      this.$emit("search", bc_id);
      console.log(bc_id);
      this.$parent.fetchSnapshot();
    },
    // 确定按钮
    handleOk(e) {
      // this.ModalText = "The modal will be closed after two seconds";
      this.confirmLoading = true;
      this.visible = false;
      this.confirmLoading = false;
      console.log("Clicked 确定 button");
      this.search();
      this.getTimes();
      this.stopZhiBo();
      // console.log(this.IptValue);
    },

    // 重置按钮
    handleCancel(e) {
      console.log("Clicked 重置 button");
      this.queryParam = "";
      // this.getTimes();
      // this.stopZhiBo();

      console.log(this.IptValue);
    },
    // 分公司下拉框的事件
    handleChange(value) {
      // console.log(value);
    },
    // 多选框
    onChange(e) {
      console.log("点击了推荐");
      this.show = !this.show;
      this.show2 = !this.show2;
    },
    onChange2(e) {
      console.log("点击了自定义播放时间段");
      this.show = !this.show;
      this.show2 = !this.show2;
    },

    //时间选择
    // 获取当前时间
    getNowTime() {
      var date = new Date();
      var hour = date.getHours();
      var minute = date.getMinutes();
      var time = this.addZero(hour) + ":" + this.addZero(minute);
      console.log(time);
      this.startTime = time;
      return this.startTime;
    },
    //小于10的拼接上0字符串
    addZero(s) {
      return s < 10 ? "0" + s : s;
    },

    changeTime(val, dateStrings, type) {
      if (type === "startTime") {
        this.startTime = dateStrings;
      } else {
        this.endTime = dateStrings;
      }
    },
    getDisabledHours() {
      let hours = [];
      let time = this.startTime;
      let timeArr = time.split(":");
      for (var i = 0; i < parseInt(timeArr[0]); i++) {
        hours.push(i);
      }
      return hours;
    },
    getDisabledMinutes(selectedHour) {
      let time = this.startTime;
      let timeArr = time.split(":");
      let minutes = [];
      if (selectedHour == parseInt(timeArr[0])) {
        for (var i = 0; i < parseInt(timeArr[1]); i++) {
          minutes.push(i);
        }
      }

      return minutes;
    },
    // 设置推迟时间结束直播
    // 获取结束直播推迟的时间
    // 1s=1000毫秒
    getTimes() {
      let startTimeHour = this.startTime.split(":")[0] * 1;
      let startTimeMinutes = this.startTime.split(":")[1] * 1;
      let endTimeHour = this.endTime.split(":")[0] * 1;
      let endTimeMinutes = this.endTime.split(":")[1] * 1;
      let endTimeNum = endTimeHour * 60 + endTimeMinutes;
      // console.log(startTimeHour);
      // console.log(startTimeMinutes);
      // console.log(endTimeHour);
      // console.log(endTimeMinutes);
      // 处理时间
      if (endTimeNum != 0) {
        let Hour = endTimeHour - startTimeHour;
        let Minutes = endTimeMinutes - startTimeMinutes;
        this.TimeOut = Minutes * 60 * 1000 + Hour * 60 * 60 * 1000;
      } else {
        this.TimeOut = this.IptValue * 60 * 60 * 1000;
      }
    },
    stopZhiBo() {
      setTimeout(() => {
        // console.log(this.TimeOut);
        // console.log(this.IptValue);
        // this.$refs.stopVideo.startVideo();
        // this.$refs.stopVideo.stopVideo();
        this.$emit("ok");
        // console.log("2222");
      }, this.TimeOut);
      // }, 500);
    },
    inputChange(val) {
      if (typeof val === "string") {
        return !isNaN(Number(val)) ? val.replace(/\./g, "") : 0;
      } else if (typeof val === "number") {
        return !isNaN(val) ? String(val).replace(/\./g, "") : 0;
      } else {
        return 0;
      }
    },
  },
};
</script>
<style scoped lang="less">
.setBtn {
  font-size: 16px;
}
.SS_search {
  display: none;
}

.radio_out {
  margin-top: 15px;
}
.TimeIpt {
  width: 60px;
  height: 40px;
  border: 1px black solid;
  margin: 0 10px;
  color: black;
  .ant-input-number {
    line-height: 25px;
    text-align: center;
  }
}
</style>
