<template>
  <div class="v-container">
    <div class="sub-container">
      <!-- 顶部标题 -->
      <div class="v-title" v-show="false">
        <div class="v-title-left">{{ nowTime }}</div>
        可视化案场

        <div class="v-title-right1">
          <!-- 单屏和多屏切换 -->
          <div class="Split">
            <!-- <span @click="isshowMorePreview">10分屏</span> -->
          </div>
        </div>

        <div class="v-title-right2">
          <span @click="clickFun" class="quanping"
            ><i class="iconfont icon-quanping">{{
              isFullFlag ? "退出全屏" : "全屏"
            }}</i></span
          >
        </div>
      </div>
      <!-- 数据 -->
      <div v-show="isShow">
        <!-- <div> -->
        <div style="display: flex; margin-top: 2%">
          <div style="flex: 1; padding-right: 20px">
            <!--直播 -->
            <!--:pageType="'patrol' " || 'caseField' -->
            <SitePreview
              ref="site_preview"
              :record="record"
              :pageType="'caseField' && 'patrol'"
              :key="componentKey"
              :pageTypecaseField="pageTypecaseField"
            ></SitePreview>
          </div>
          <div class="v-msg">
            <div style="height: 92%">
              <div class="caseBTN">
                <!-- 自动播放点击事件 -->
                <div>
                  <span class="bofangBtn" @click="autoplay"
                    ><span v-show="startShow">开启自动播放</span
                    ><span v-show="closedShow">关闭自动播放</span></span
                  >
                </div>
                <div class="v-title-right">
                  <!-- 设置按钮 -->
                  <case-set
                    ref="CaseSet"
                    @search="onsearch"
                    @ok="stopZhiBo"
                  ></case-set>
                  <!-- <span @click=""><i class="iconfont icon-shezhi" /> 设置</span> -->
                  <!-- <span @click="jumpBack"><a-icon type="logout" /> 退出</span> -->
                </div>
              </div>
              <a-row>
                <a-col :span="8" v-for="(item, i) in plainOptions" :key="i">
                  <img
                    :id="'videoImg' + i"
                    :src="item.label"
                    :alt="item.value"
                    class="check-img"
                    @click="siteChange(i)"
                  />
                </a-col>
              </a-row>
            </div>
            <a-pagination
              class="v-page"
              v-model="current"
              :default-current="1"
              :total="total"
              :page-size="12"
              :show-total="(total) => `共 ${total} 条`"
              @change="onChangeDate"
              show-less-items
              show-quick-jumper
            />
          </div>
        </div>
      </div>
      <!-- 没有样板工地时显示 -->
      <div v-show="isNoCaseShow">
        <no-case @changeList="changeList"></no-case>
      </div>
    </div>
    <a-modal
      class="transfer-modal"
      title="轮播列表"
      okText="确定"
      cancelText="取消"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="setOk"
      @cancel="setCancel"
    >
      <a-transfer
        class="site-trans"
        :data-source="mockData"
        show-search
        :titles="titles"
        :filter-option="filterOption"
        :target-keys="targetKeys"
        :render="(item) => item.title"
        @change="handleChange"
        @search="handleSearch"
      />
    </a-modal>
  </div>
</template>
<script>
import SitePreview from "@/views/site/SitePreview.vue";
import { GetScreenWorkList } from "../../api/screen.js"; //查询角色信息列表
import LiveBroadcast from "../patrol/LiveBroadcast.vue";
import PatrolCarousel from "./PatrolCarousel.vue";
import screenfull from "screenfull";
import { GetScreenWorksiteNewImage } from "@/api/screen"; //可视化案场获取工地最新快照
import { requestImage } from "@/utils/fileRequest";
import { urlConfig } from "@/config/config";
// 引入设置按钮弹窗
import CaseSet from "./CaseSet.vue";
import NoCase from "./NoCase.vue";

export default {
  name: "SingleScreen",
  props: {
    showOnePreview: {
      typeof: Boolean,
      default: false,
    },
  },
  components: { SitePreview, LiveBroadcast, PatrolCarousel, CaseSet, NoCase },
  data() {
    return {
      isFullFlag: false,
      current: 1,
      total: 0,
      componentKey: 50,
      queryParam: {
        ws_id: "",
        // s_time: "1663663074",
        bc_id: "",
        pageNo: 2,
        pageSize: 12,
      },
      // 开启、关闭自动播放
      startShow: false,
      closedShow: true,
      // NoCase是否显示
      isShow: true,
      isNoCaseShow: false,
      ws_id: "",
      bc_id: "",
      nowTime: "", // 当前日期时间
      screenCase: [],
      pictureList: [],
      visible: false,
      confirmLoading: false,
      // transfer
      titles: ["工地列表", "已选工地"],
      mockData: [],
      targetKeys: [],
      //赋值链表
      listTemp: [
        { description: "", title: "" },
        { description: "", title: "" },
        { description: "", title: "" },
        { description: "", title: "" },
        { description: "", title: "" },
        { description: "", title: "" },
      ],
      //巡查展示判断
      listShow: {
        show0: true,
        show1: true,
        show2: true,
        show3: true,
        show4: true,
        show5: true,
      },
      //次数
      playnum: 0,
      plainOptions: [], // 所有的选项
      record: {
        ws_name: "",
        ws_id: "",
        w_power: "",
      },
      // 图片地址
      pathUrl:
        urlConfig.baseUrl + "/files/picture/snapshot/snapshotpicture?filePath=",
      pageTypecaseField: "2",
      timer: null,
    };
  },
  mounted() {
    this.getTime();
    this.fetchData();
    this.fetchScreenSite("init");
    this.autoSite(true);
  },
  created() {
    this.queryParam.ws_id = this.record.ws_id;
    this.onChangeDate();
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
    if (this.siteTimer) {
      clearInterval(this.siteTimer);
    }
  },
  destroyed() {
    // 浏览器页面关闭断开视频
    window.removeEventListener("beforeunload", () =>
      this.$refs["LiveBroadcast" + 0].stopVideo()
    );
  },
  methods: {
    stopZhiBo() {
      // console.log("99");
      // 调用直播页面的关闭视频的方法
      this.$refs.site_preview.close();
    },

    clickFun() {
      this.isFullFlag = !this.isFullFlag;
      screenfull.toggle();
    },
    // 自动播放点击事件
    autoplay() {
      if (this.startShow == true) {
        this.autoSite();
        this.startShow = false;
        this.closedShow = true;
      } else {
        clearInterval(this.siteTimer); // 关闭定时器
        this.startShow = true;
        this.closedShow = false;
      }
      // console.log("startShow" === this.startShow);
      // console.log("closedShow" === this.closedShow);
    },

    autoSite(isFirst) {
      if (isFirst) {
        // console.log("是否第一次：", isFirst);
        this.playList = [];
        this.videoIndex = 0;
        this.currentPage = 1;
        this.getVideoId("1").then(() => {
          this.record.ws_id = this.playList[0] ? this.playList[0] : null;
          setTimeout(() => {
            this.componentKey = 0;
            this.record.ws_id = this.playList[0] ? this.playList[0] : null;
            // this.record.ws_id = this.plainOptions[0] ? this.plainOptions[0].ws_id : null;
            const videoImg = document.getElementById("videoImg0");
            // 有bug
            videoImg.style.border = "solid #04c2d5";
          }, 500);
        });
      } else {
        // console.log("点击之后：", this.videoIndex);
      }
      this.siteTimer = setInterval(() => {
        this.videoIndex++;
        // console.log(this.playList[this.componentKey], this.componentKey)
        if (this.playList[this.videoIndex]) {
          const videoImg = document.getElementById(
            "videoImg" + String(this.videoIndex - 1)
          );
          if (videoImg) {
            // console.log("清除第" + (this.videoIndex - 1) + "个边框");
            videoImg.style.border = "";
          }
          this.componentKey = this.videoIndex;
          this.record.ws_id = this.playList[this.videoIndex]
            ? this.playList[this.videoIndex]
            : null;
          console.log("正在播放第" + this.videoIndex + "个");
          if (this.currentPage === this.current) {
            const videoImg = document.getElementById(
              "videoImg" + this.componentKey
            );
            if (videoImg) {
              videoImg.style.border = "solid #04c2d5";
            }
          }
        } else {
          this.videoIndex = 0;
          this.playList = [];
          const pageNum =
            this.total % this.queryParam.pageSize === 0
              ? this.total / this.queryParam.pageSize
              : this.total / this.queryParam.pageSize + 1;
          if (this.currentPage === this.current) {
            if (this.current === pageNum) {
              this.current = 1;
            } else {
              this.current = this.current + 1;
            }
            this.onChangeDate();
          }
          this.currentPage = this.currentPage + 1;
          // 已经翻页了
          if (this.currentPage <= pageNum) {
            // 不是最后一页的时候
            this.getVideoId(String(this.currentPage)).then(() => {
              this.componentKey = 0;
              this.record.ws_id = this.playList[0] ? this.playList[0] : null;
              console.log("播放第0个");
              const videoImg = document.getElementById("videoImg0");
              videoImg.style.border = "solid #04c2d5";
            });
          } else {
            // 最后一页，跳转第一页
            this.currentPage = 1;
            this.getVideoId("1").then(() => {
              this.componentKey = 0;
              this.record.ws_id = this.playList[0] ? this.playList[0] : null;
              const videoImg = document.getElementById("videoImg0");
              videoImg.style.border = "solid #04c2d5";
            });
          }
        }
        // }, 1000 * 60 * 1); //播放时间
      }, 1000 * 20);
    },
    // sleep(numberMillis){
    //     var now = new Date();
    //     var exitTime = now.getTime() + numberMillis;
    //     while (true) {
    //       now = new Date();
    //       if (now.getTime() > exitTime) return;
    //     }
    //   },
    getTime() {
      this.timer = setInterval(() => {
        let timeDate = new Date();
        let year = timeDate.getFullYear();
        let mounth = timeDate.getMonth() + 1;
        mounth = mounth >= 10 ? mounth : "0" + mounth;
        let day = timeDate.getDate();
        day = day >= 10 ? day : "0" + day;
        let hours = timeDate.getHours();
        hours = hours >= 10 ? hours : "0" + hours;
        let minutes = timeDate.getMinutes();
        minutes = minutes >= 10 ? minutes : "0" + minutes;
        let seconds = timeDate.getSeconds();
        seconds = seconds >= 10 ? seconds : "0" + seconds;
        let week = timeDate.getDay();
        let weekArr = [
          "星期日",
          "星期一",
          "星期二",
          "星期三",
          "星期四",
          "星期五",
          "星期六",
        ];
        this.nowTime = `${year}-${mounth}-${day} ${hours}:${minutes}:${seconds} ${weekArr[week]}`;
      }, 1000);
    },
    // 分页
    onChange(pageNo, size) {
      this.$refs.vSearch.changePageSearch(pageNo);
    },
    changeList(result, page, sum) {
      this.list = result;
      this.current = page;
      this.total = sum;
    },
    siteChange(index) {
      // this.componentKey = index
      // this.record.ws_id = this.plainOptions[index] ? this.plainOptions[index].ws_id : null;
      // console.log("this.plainOptions.value", this.plainOptions[index].value, index);
      // console.log("siteChange1"+this.componentKey);
      // console.log("siteChange2"+result);
      if (this.siteTimer) {
        clearInterval(this.siteTimer);
        this.siteTimer = null;
      }
      this.videoIndex = index;
      this.autoSite(false);
      this.currentPage = this.current;
      this.getVideoId(this.current);
      this.componentKey = index;
      this.record.ws_id = this.plainOptions[index]
        ? this.plainOptions[index].ws_id
        : null;
      this.clearVideoImgBorder();
      const videoImg = document.getElementById("videoImg" + index);
      videoImg.style.border = "solid #04c2d5";
    },
    clearVideoImgBorder() {
      for (let i = 0; i < this.plainOptions.length; i++) {
        const tmp = document.getElementById("videoImg" + i);
        if (tmp) {
          tmp.style.border = "";
        }
      }
    },
    onChangeDate(date, dateString) {
      let dateS = dateString ? dateString : new Date();
      let dates = new Date(dateS).getTime() / 1000; // 秒
      // this.queryParam.s_time = parseInt(dates);
      // console.log(this.current);
      this.clearVideoImgBorder();
      if (this.current === this.currentPage) {
        const videoImg = document.getElementById("videoImg" + this.videoIndex);
        videoImg.style.border = "solid #04c2d5";
      }
      this.queryParam.pageNo = this.current;
      // this.queryParam.pageSize = this.current;
      this.fetchSnapshot();
    },
    //请求侧边栏接口
    fetchSnapshot() {
      //请求侧边栏接口
      // this.pageNo = queryParam.pageNo
      // this.pageSize = queryParam.pageSize
      let order = ["bc_id", "pageNo", "pageSize"];
      const params = {
        ...this.queryParam,
        verify: this.$verify(this.queryParam, order),
      };
      // console.log(params);
      // 可视化案场获取工地最新的快照
      // alert(0)
      GetScreenWorksiteNewImage(params).then((res) => {
        this.current = res.data.page;

        this.total = res.data.sum;
        // 处理数据
        const url = this.pathUrl;
        let data = res.data.data;
        let list = [];
        data.forEach((el, i) => {
          // console.log(i, el.ws_id)
          list[i] = {
            value: i,
            label: require("../../assets/images/imgloading.gif"),
            ws_id: el.ws_id,
            bc_id: el.bc_id,
          };
          if (el.s_url.length > 0) {
            requestImage(url + el.s_url, function (resurl) {
              list[i].label = resurl;
            });
          }
        });

        this.plainOptions = list;
        // console.log(this.plainOptions.length);
        // 控制是否显示NoCase页面
        if (this.plainOptions == 0) {
          this.isShow = false;
          this.isNoCaseShow = true;
        } else {
          this.isShow = true;
          this.isNoCaseShow = false;
        }
      });
      // console.log("fetchSnapshot" + this.plainOptions);
    },

    changeList(result, page, sum) {
      this.plainOptions = result;
      this.current = page;
      this.total = sum;
    },
    // 子组件传过来的bc_id
    onsearch(id) {
      this.queryParam.bc_id = id ? id : "";
      console.log(this.queryParam);

      // this.bc_id = id ? id : "";
      this.fetchSnapshot();
      // this.getVideoId();
      // console.log(this.bc_id);
    },

    getVideoId(pageNo) {
      // this.pageNo = queryParam.pageNo
      // this.pageSize = queryParam.pageSize
      let order = ["bc_id", "pageNo", "pageSize"];

      const params = {
        ws_id: "",
        // s_time: "1663663074",
        // bc_id: this.bc_id,
        bc_id: "",
        pageNo: pageNo,
        pageSize: this.queryParam.pageSize,
        verify: this.$verify(this.queryParam, order),
      };
      return new Promise((resolve, rejec) => {
        GetScreenWorksiteNewImage(params).then((res) => {
          // 处理数据
          let data = res.data.data;
          let list = [];
          data.forEach((el, i) => {
            list[i] = el.ws_id;

            // list[i] = {
            //   ws_id: el.ws_id,
            //   bc_id: this.bc_id,
            // };
          });
          this.playList = list;

          data.forEach((el, i) => {
            this.record.w_power = el.w_power;
          });
          // console.log(this.record.w_power);
          resolve();
          // console.log(this.playList); //id
          // console.log(data);
        });
      });
    },

    //新子组件加载
    newAssembly(arr, len) {
      let timeplay = 20000;
      let newArr1 = [].concat(arr);
      let newArr2 = [].concat(arr);

      if (this.playnum == len) this.playnum = 0;
      //前三个窗口直播，后三个窗口快照
      if (this.playnum <= 2) {
        //视频断开
        this.$refs["LiveBroadcast" + this.playnum].stopVideo();
        this.listShow["show" + this.playnum] = false;
        console.log("执行次数" + this.playnum);
        //视频赋值
        this.listTemp[this.playnum] = newArr1[0];
        this.$refs["LiveBroadcast" + this.playnum].propsData(newArr1[0]);
        //视频开始
        this.$refs["LiveBroadcast" + this.playnum].startVideo();
        this.listShow["show" + this.playnum] = true;
        newArr2.push(newArr2.shift());

        console.log("执行结束");
      } else {
        //快照更换
        this.listShow["show" + this.playnum] = false;

        console.log("执行次数" + this.playnum);
        //快照赋值
        this.listTemp[this.playnum] = newArr1[0];
        this.$refs["LiveBroadcast" + this.playnum].propsData(newArr1[0]);
        //快照开始
        this.$refs["LiveBroadcast" + this.playnum].startPatrol();
        this.listShow["show" + this.playnum] = true;
        newArr2.push(newArr2.shift());

        console.log("执行结束");
      }
      this.playnum++;
      // if(this.playnum!=6)
      if (this.playnum == len && len != 6) {
        setTimeout(() => {
          this.newAssembly(newArr2, newArr2.length - 1);
        }, (6 - len) * timeplay);
      } else {
        setTimeout(() => {
          this.newAssembly(newArr2, newArr2.length - 1);
        }, timeplay);
      }
    },
    // getImgs() {
    //   for(let i = 0; i < 3; i++) {
    //     this.pictureList.push(
    //       {
    //         id: i,
    //         imgList: [
    //           require("../../assets/images/preview_default.png"),
    //           require("../../assets/images/preview_default.png"),
    //           require("../../assets/images/preview_default.png"),
    //         ]
    //       }
    //     )
    //   }
    // },
    jumpBack() {
      this.$router.push({ path: "SampleSite" });
    },
    // 初始化
    fetchScreenSite(init) {
      const targetKeys = [];
      this.screenCase = JSON.parse(localStorage.getItem("screenCase")) || [];
      if (init == "init") {
        if (this.screenCase.length > 6) {
          this.newAssembly(this.screenCase, 6);
        } else if (this.screenCase.length > 1) {
          this.newAssembly(this.screenCase, this.screenCase.length - 1);
        }
      }
      this.screenCase.forEach((el) => {
        targetKeys.push(el.key);
      });
      this.targetKeys = targetKeys;
    },
    handleSet() {
      this.visible = true;
      this.fetchScreenSite();
    },
    setOk() {
      const mockData = this.mockData;
      const list = [];
      mockData.forEach((el) => {
        if (this.targetKeys.indexOf(el.key) > -1) {
          list.push(el);
        }
      });
      localStorage.setItem("screenCase", JSON.stringify(list));
      this.visible = false;
      this.fetchScreenSite();
    },
    setCancel() {
      this.visible = false;
    },
    // transfer
    // transfer
    fetchData() {
      GetScreenWorkList().then((res) => {
        const mockData = [];
        res.data.forEach((el) => {
          const data = {
            key: el.ws_id,
            title: el.ws_name,
            description: el.ws_area,
            chosen: false,
          };
          mockData.push(data);
        });
        this.mockData = mockData;
      });
    },
    filterOption(inputValue, option) {
      // console.log("1");
      return option.title.indexOf(inputValue) > -1;
    },
    handleChange(targetKeys, direction, moveKeys) {
      console.log(targetKeys, direction, moveKeys);
      this.targetKeys = targetKeys;
    },
    handleSearch(dir, value) {
      console.log("search:", dir, value);
    },
    isshowMorePreview() {
      this.showOnePreview = true;
      // console.log(this.showOnePreview);
    },
  },
};
</script>
<style lang="less" scoped>
.v-container {
  background: #0a1621;
  min-width: 1200px;
  height: 100%;
  overflow: auto;
}
.sub-container {
  min-height: 100%;
  margin: 0 10px;
}
.v-title {
  position: relative;
  color: #66ffff;
  height: 75px;
  line-height: 75px;
  font-size: 27px;
  background: url("../../assets/images/visualize/title_inspection.png")
    no-repeat;
  background-position: center center;
  background-size: auto 100%;
  .v-title-left {
    position: absolute;
    left: 30px;
    color: #fff;
    font-size: 14px;
    line-height: 60px;
  }
  .v-title-right {
    position: absolute;
    right: 30px;
    top: 0;
    color: #fff;
    font-size: 14px;
    line-height: 60px;
    cursor: pointer;
    &:hover {
      color: #66ffff;
    }
  }
  .v-title-right2 {
    position: absolute;
    right: 7%;
    top: 0;
    color: #fff;
    font-size: 14px;
    line-height: 60px;
    cursor: pointer;
  }
  .quanping {
    &:hover {
      color: #66ffff;
    }
  }
  .v-title-right1 {
    position: absolute;
    display: flex;
    right: 13%;
    top: 0;
    color: #fff;
    font-size: 16px;
    line-height: 60px;
    .Split {
      margin-left: 10px;
    }
    cursor: pointer;
    .bofangBtn,
    .Split {
      &:hover {
        color: #66ffff;
      }
    }
  }
}

.ant-row {
  padding: 35px;
}
.ant-col {
  padding: 6px;
  .v-box {
    padding: 16px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background-color: #0a1341;
    background-image: url("../../assets/images/visualize/border1.png"),
      url("../../assets/images/visualize/border2.png"),
      url("../../assets/images/visualize/border3.png"),
      url("../../assets/images/visualize/border4.png");
    background-repeat: no-repeat;
    background-position: top left, top right, bottom right, bottom left;
  }
}
.v-box-title {
  color: #fff;
  height: 20px;
  line-height: 20px;
  font-weight: 500;
  font-size: 15px;
  text-align: left;
}
.v-box-video {
  margin-top: 20px;
  width: 100%;
  max-height: 300px;
}
.transfer-modal /deep/ .ant-modal {
  width: 1000px !important;
}
.site-trans /deep/ .ant-transfer-list {
  width: 450px;
  height: 300px;
}
.check-img {
  width: 100%;
  height: 130px;
  vertical-align: top;
  margin-top: 20px;
  margin-right: 0px;
  border-radius: 10px;
}
.enlarge-img:hover {
  color: #1890ff;
}
.v-msg {
  width: 35%;
}
.v-page {
  margin-bottom: 20px;
  /deep/ .ant-pagination-total-text {
    color: #ccc;
  }
  /deep/ .ant-pagination-item-link {
    background-color: rgba(0, 0, 0, 0);
    color: #fff;
  }
  /deep/ .ant-pagination-item {
    background-color: rgba(0, 0, 0, 0);
    &:hover {
      border-color: #47b4ea;
    }
    a {
      color: #fff;
      &:hover {
        color: #47b4ea;
      }
    }
  }
  /deep/ .ant-pagination-item-active {
    background-color: #47b4ea;
    &:hover {
      border-color: #47b4ea;
    }
    a {
      &:hover {
        color: #fff;
      }
    }
  }
  /deep/ .ant-pagination-item-ellipsis {
    color: #fff;
  }
  /deep/ .ant-pagination-options-quick-jumper {
    color: #ccc;
    input {
      color: #47b4ea;
      background-color: rgba(0, 0, 0, 0);
    }
  }
}
.caseBTN {
  display: flex;
  color: #fff;
  cursor: pointer;
}
</style>
