<template>
  <a-row class="card-row">
    <a-col
      :span="12"
      v-for="(item, i) in imgList"
      :key="'item' + i"
      class="card-col"
    >
      <a-carousel :autoplay="true" :after-change="onChange">
        <div v-for="(pic, j) in item.list" :key="'pic' + j">
          <img :src="pic.split(',')[0]" class="card-img" />
        </div>
      </a-carousel>
    </a-col>
    <a-col :span="12" v-show="imgList.length === 0" class="card-null"
      >暂无图片</a-col
    >
  </a-row>
</template>
<script>
import { GetSnapshot } from "@/api/site";
import { urlConfig } from "@/config/config";
import { requestImage } from "@/utils/fileRequest";

export default {
  props: {
    site: {
      type: Object,
      default: {},
    },
    videoId: "",
  },
  name: "PatrolCarousel",
  data() {
    return {
      queryParam: {
        ws_id: "",
        s_time: "",
      },
      originData: [],
      imgList: [],
      propsSite: {},
      // 图片地址
      pathUrl:
        urlConfig.baseUrl + "/files/picture/snapshot/snapshotpicture?filePath=",
    };
  },
  created() {
    // this.queryParam.ws_id = this.site.key;
    // this.queryParam.s_time = this.$moment(new Date()).format("X"); // 默认查询当天快照数据
    // this.fetchSnapshot();
  },
  methods: {
    onChange() {},
    fetchSnapshot() {
      let order = ["ws_id", "s_time"];
      const params = {
        ...this.queryParam,
        verify: this.$verify(this.queryParam, order),
      };
      GetSnapshot(params).then((res) => {
        // 处理数据
        if (res.data.length == 0) {
          this.$message.warn("暂无图片");
        } else {
          this.originData = res.data;
          this.dealImgs();
        }
      });
    },
    // 把imgList分为多个走马灯数组
    dealImgs() {
      const url = this.pathUrl;
      const list = [];
      this.originData.forEach((el, index) => {
        let obj = {};
        if (index < 6) {
          obj = {
            id: index,
            list: [],
          };
          requestImage(url + el, function (resurl) {
            obj.list.push(resurl);
          });
          list.push(obj);
        } else {
          let i = index % 6;
          requestImage(url + el, function (resurl) {
            list[i].list.push(resurl);
          });
        }
      });
      this.imgList = list;
    },
    // 动态传值
    propsData(data) {
      this.propsSite = data;
    },
    // 工地快照初始化
    startPatrol() {
      console.log("第" + this.videoId + "窗口");
      this.queryParam.ws_id = this.propsSite.key;
      this.queryParam.s_time = this.$moment(new Date()).format("X"); // 默认查询当天快照数据
      this.fetchSnapshot();
    },
  },
};
</script>
<style lang="less" scoped>
.card-row {
  padding: 0 !important;
  margin: 0;
}
.card-col {
  padding: 3px;
}
.card-img {
  width: 100%;
  height: 100px;
}
.card-null {
  text-align: center;
  color: #fff;
}
</style>