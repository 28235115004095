<!-- 直播 -->
<template>
  <div>
    <!-- <div class="modal-content">
      <a-icon type="environment" theme="twoTone" class="model-site-name" />
      <span>{{ record.ws_name }}</span>
    </div> -->
    <div class="divScreenshot">
      <!-- 屏幕截图 -->
      <canvas width="97%" height="380px" id="screenshot"></canvas>
    </div>
    <div class="myVideo_out">
      <!--  :class="
        pageType === 'patrol'
          ? 'modal-content-nobg'
          : 'modal-content  modal-content1'
      " -->
      <!-- <div v-for="(item, i) in record" :key="i" class="myVideo_out1"> -->
      <div
        v-for="(item, i) in record"
        :key="i"
        :class="className"
        @mouseenter="ShowCanceSampleSiteBTN(i, 'block', 'true')"
        @mouseleave="ShowCanceSampleSiteBTN(i, 'none', 'false')"
      >
        <!-- width="235px" height="380px"  height="100%"-->

        <video
          autoplay
          controls
          width="97%"
          height="95%"
          class="myVideo"
          :id="'myVideo' + i"
          crossorigin="*"
          style="background: black; object-fit: cover"
          :poster="posterImg[i]"
        ></video>
        <div class="v-box-info">
          <div class="v-box-title">
            {{
              item.ws_name
                .replace(/直辖市|-北京|-天津/g, "")
                .replace(/[0-9]/g, "*")
            }}
          </div>
          <div>
            <span>
              {{ "工程管家：" + (item.w_name_gj ? item.w_name_gj : "") }}</span
            >
            <span class="w_name_jl_left">
              {{ "项目经理：" + (item.w_name_jl ? item.w_name_jl : "") }}</span
            >
          </div>

          <!-- <div style="position: absolute; bottom: 5px; right: 10px">
                {{ "剩余" + $calcDate(item.d_remain_day) }}
              </div> -->
        </div>
        <div class="controlBtn">
          <div
            :id="'CanceSampleSiteBtn' + i"
            style="display: none; padding: 2px 2px; font-weight: 800"
            @click="CanceSampleSite(item.ws_id, 1, i)"
          >
            取消样板工地
          </div>
        </div>
        <!-- <div class="controlBtn" v-show="!isSampleSite">
          <div
            :id="'setSampleSiteBtn' + i"
            style="display: none; padding: 2px 2px"
            @click="CanceSampleSite(item.ws_id, 2, i)"
          >
            设置样板工地
          </div>
        </div> -->
        <!-- 已取消弹出框提示 -->
        <div class="Cance_ed">
          <div
            :id="'Cance_ed' + i"
            style="
              display: none;
              background-color: rgba(0, 0, 0, 0.4);
              padding: 10px 20px;
            "
          >
            已取消
          </div>
        </div>
        <!-- @click.right="ShowCanceSampleSiteBTN()" v-if="isSampleSite"-->
      </div>
      <!-- <div class="opt-button opt_button1 opt_button2"> -->
      <!-- 信号展示 -->
      <!-- <div class="xinhao opt_button1">
          <div class="xinhaoRed" v-show="NoXinhao">
            <a-icon type="close" />
          </div>
          <div id="div_sign">
            <div
              style="
                width: 6px;
                height: 3px;
                margin-top: 16px;
                border: 1px solid #0e2d5f;
              "
            ></div>
            <div
              style="
                width: 6px;
                height: 6px;
                margin-top: -6px;
                margin-left: 5px;
                border: 1px solid #0e2d5f;
              "
            ></div>
            <div
              style="
                width: 6px;
                height: 9px;
                margin-top: -9px;
                margin-left: 10px;
                border: 1px solid #0e2d5f;
              "
            ></div>
            <div
              style="
                width: 6px;
                height: 12px;
                margin-top: -12px;
                margin-left: 15px;
                border: 1px solid #0e2d5f;
              "
            ></div>
            <div
              style="
                width: 6px;
                height: 15px;
                margin-top: -15px;
                margin-left: 20px;
                border: 1px solid #0e2d5f;
              "
            ></div>
          </div>
        </div> -->

      <!-- 全屏按钮 -->
      <!-- <div class="v-btn" @click="quanping()">
          <img
            src="../../assets/images/site_img/fullscreen_img.png"
            alt="全屏"
            height="15px"
          />
        </div> -->
      <!-- </div> -->
      <!-- <div class="direction-button" >
        <a-row>
          <a-col :span="8"></a-col>
          <a-col :span="8" class="dir-btn" @click="moveCamera('up', '上')">
            <a-icon type="caret-up" />
          </a-col>
          <a-col :span="8"></a-col>
          <a-col :span="8" class="dir-btn" @click="moveCamera('left', '左')">
            <a-icon type="caret-left" />
          </a-col>
          <a-col :span="8"></a-col>
          <a-col :span="8" class="dir-btn" @click="moveCamera('right', '右')">
            <a-icon type="caret-right" />
          </a-col>
          <a-col :span="8"></a-col>
          <a-col :span="8" class="dir-btn" @click="moveCamera('down', '下')">
            <a-icon type="caret-down" />
          </a-col>
          <a-col :span="8"></a-col>
        </a-row>
      </div> -->
    </div>
    <!-- <a-modal
      :visible="visible"
      title="确认要重启此设备？"
      :confirm-loading="confirmLoading"
      @ok="restart"
      @cancel="restartCancel"
    >
      <p style="color: red">
        此步骤请谨慎操作！<br />注意：预计重启需要五分钟。
      </p>
    </a-modal> -->
  </div>
</template>

<script>
import {
  GetSiteVideo,
  StopSiteVideo,
  OnCall,
  OffCall,
  CameraMove,
  CameraReset,
  CameraRestart,
  CameraStatus,
  videoSignal,
  GetWsbinddevices,
  getSitPreset,
  GBVideoLive,
} from "@/api/video";
import { SetSampleSite } from "@/api/site";
import flvjs from "flv.js";
import LineImage from "../datav/LineImage.vue";
import { GetScreenWorksiteNewImage } from "@/api/screen"; //可视化案场获取工地最新快照
import { urlConfig } from "@/config/config";
export default {
  components: {
    LineImage,
  },
  name: "CasePreview", // 直播
  props: {
    record: {
      type: Array,
      default: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
    },
    show_reviewNum: {
      type: String,
    },
    queryParam2: {
      type: Object,
    },
  },
  ws: null,
  soundrecord: null,
  data() {
    return {
      timer: null,
      bc_id: "",
      ws_id: "",
      video: {
        com_only: "",
        video_url: "",
        audio_url: "",
        stream_id: "",
        push_stream_id: "",
      },
      queryParamIMG: {
        ws_id: "",
        bc_id: "",
        ws_bind_status: "2",
        ws_status: "1",
        d_status: "1",
        ws_model: "1",
        pageNo: 1,
        pageSize: 10,
      },
      voiceCall: false,
      cycleMax: 15,
      cycleTime: 1,
      flvPlayer: null,
      // 弹出框
      visible: false,
      confirmLoading: false,
      wsModel: "0",
      type: "2",
      typecaseField: "2",
      ModelcaseField: "2",
      // stream_url: "webrtc://dsm.wisecommey.com:1990/stream/",
      stream_url: "webrtc://dsm.ccom.com.cn:1990/stream/",
      Video_Status: "",
      xinhao: null,
      NoXinhao: false,
      jingyin: 1,
      bofang: 2,
      signalResData2: "",
      getvideoSignalTimer: null,
      ModalText: "Content of the modal",
      recordwsid: "",
      // posterImg0:
      //   urlConfig.baseUrl +
      //   "/files/picture/snapshot/snapshotpicture?filePath=/snapshot/hourPhoto/2023-05-18/1962_1684401059953.png", //视频封面，没有播放时显示的图片
      posterImg: [],
      className: "myVideo_out1", //动态class名
      ComeonlyWsid: [],
      isSampleSite: true,
      resData: "",
      liveImei: "",
      liveImeiList: [],
      gbVideoValue: {},
      startPlayGBSIndex: [],
    };
  },
  created() {
    this.GetNewImage();
    // 初始化数据 触发监听  data是props
    setTimeout(() => {
      // if (this.record.length > 0) {
      // console.log("this.record", this.record);

      this.record.forEach((element, i) => {
        if (element.ws_id) {
          this.ws_id = element.ws_id;
          // this.getVideo(i);
        }
        // 静音
        let jingyin = document.getElementById("myVideo" + i);
        jingyin.muted = true;
        console.log("6666");
        // console.log(this.ws_id);
        // setTimeout(() => {
        // 获取摄像头状态
        // this.videoStatus("cycle");
        // });
        // 拉流;
        // var videoElement = document.getElementById("myVideo" + i);
        // this.startPlay(this.stream_url + this.video.stream_id, videoElement);
        // 静音
        // videoElement.muted = 0;
        // // // 推流
        // this.startPublish(
        //   this.stream_url + this.video.push_stream_id,
        //   new Audio()
        // );
      });
      // }
    }, 1500);
  },

  mounted() {
    window.onunload = function (e) {
      window.clearTimeout(this.timer); // 清理定时器
      // this.offCall();
      // this.stopVideo();
      this.close();
    };
    window.addEventListener("beforeunload", (e) => {
      e = e || window.event;
      if (e) {
        // e.returnValue = "关闭提示";
        // }
        this.close();
        // return "关闭提示";
        // const url =
        //   urlConfig.baseUrl +
        //   "/dsmcore/api/worksite/camera/unvideo?" +
        //   "ws_id=" +
        //   this.ws_id +
        //   "&com_only=" +
        //   this.video.com_only;
        // const formData = new FormData();
        // formData.append("ws_id", this.ws_id);
        // formData.append("com_only", this.video.com_only);
        // fetch(url, {
        //   method: "POST",
        //   keepalive: true,
        //   headers: {
        //     token: sessionStorage.getItem("token"),
        //   },
        // }).then((res) => {
        //   // console.log(res);
        // });
      }
    });

    // 10秒后清除获取信号值的定时器
    setTimeout(() => {
      clearInterval(this.getvideoSignalTimer);
      this.getvideoSignalTimer = null;
    }, 10000);
  },
  watch: {
    // 当摄像头状态为2时开始获取信号值
    Video_Status(newStatus, oldStatus) {
      if (newStatus == 2) {
        setTimeout(() => {
          // this.getvideoSignal();
        }, 1000);
        // debugger;
      }
    },
    //如果未查询到该摄像头信号强度  两秒查询一次信号值
    signalResData2(newV, oldV) {
      if (newV == "未查询到该摄像头信号强度") {
        //如果未查询到该摄像头信号强度  两秒查询一次信号值
        this.getvideoSignalTimer = setInterval(() => {
          // this.getvideoSignal();
          // console.log("newV == '未查询到该摄像头信号强度'");
        }, 2000);
      } else if (newV != oldV) {
        console.log(newV, oldV);
        // 清除定时器
        clearInterval(this.getvideoSignalTimer);
        this.getvideoSignalTimer = null;
      }
    },
    record(NValue, OValue) {
      // console.log(NValue);
      // this.close();
      NValue.forEach((el, i) => {
        console.log("555");
        // console.log("element", el);
        this.ws_id = el.ws_id;
        this.getVideo(i);
        this.GetWsbinddevices(i, el.ws_id);
      });
    },
    // 监听分页
    queryParam2(NV, OV) {
      this.queryParamIMG.pageNo = NV.pageNo;
      this.GetNewImage();
    },
    // 监听几乘几的分屏，给preview添加不同的class名，使其有不同的样式来分屏
    show_reviewNum(NV, OV) {
      if (NV == "2x5") {
        // this.close();
        this.className = "myVideo_out1";
        this.record.forEach((el, i) => {
          this.ws_id = el.ws_id;
          this.queryParamIMG.pageSize = 10;
          this.GetNewImage();
          // this.getVideo(i);
          this.GetWsbinddevices(i, el.ws_id);
        });
      } else if (NV == "3x5") {
        // this.close();
        this.className = "myVideo_out3x5";
        this.record.forEach((el, i) => {
          this.ws_id = el.ws_id;
          this.queryParamIMG.pageSize = 15;
          this.GetNewImage();
          // this.getVideo(i);
          this.GetWsbinddevices(i, el.ws_id);
        });
      } else if (NV == "2x6") {
        // this.close();
        this.className = "myVideo_out2x6";
        this.record.forEach((el, i) => {
          this.queryParamIMG.pageSize = 12;
          this.GetNewImage();
          this.ws_id = el.ws_id;
          // this.getVideo(i);
          this.GetWsbinddevices(i, el.ws_id);
        });
      } else if (NV == "2x4") {
        // this.close();
        this.className = "myVideo_out2x4";
        this.record.forEach((el, i) => {
          this.ws_id = el.ws_id;
          this.queryParamIMG.pageSize = 8;
          this.GetNewImage();
          // this.getVideo(i);
          this.GetWsbinddevices(i, el.ws_id);
        });
      }
    },
    immediate: true,
  },
  methods: {
    // 请求快照接口
    GetNewImage() {
      let order = ["bc_id", "pageNo", "pageSize"];
      const params = {
        ...this.queryParamIMG,
        verify: this.$verify(this.queryParamIMG, order),
      };
      GetScreenWorksiteNewImage(params).then((res) => {
        res.data.data.forEach((el, i) => {
          this.posterImg.push(
            urlConfig.baseUrl +
              "/files/picture/snapshot/snapshotpicture?filePath=" +
              res.data.data[i].s_url
          );
        });
      });
    },
    // 查询工地绑定设备信息
    GetWsbinddevices(index, ws_id) {
      const param = {
        ws_id: ws_id,
      };
      const orderParam = ["ws_id"];
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetWsbinddevices(params).then((res) => {
        let ws_bind_d_ids = res.data;
        let index1 = 0;
        // console.log("index", index, ws_bind_d_ids);
        ws_bind_d_ids.forEach((el) => {
          if (el.ddefault == 1) {
            this.ws_id = el.dwsId;
            this.d_id = el.did;
            // this.getVideo(index);

            if (el.dplatform == "htx") {
              this.getVideo(index);
            } else if (el.dplatform == "gbs") {
              this.getVideoGBS(index);
              this.liveImei = el.dimei;

              this.liveImeiList.push(el.dimei);
            }
          } else {
            index1++;
            if (index1 == ws_bind_d_ids.length) {
              this.ws_id = ws_bind_d_ids[0].dwsId;
              this.d_id = ws_bind_d_ids[0].did;
              this.liveImei = ws_bind_d_ids[0].dimei;
              this.liveImeiList.push(ws_bind_d_ids[0].dimei);
              // this.getVideo(index);
              console.log("index", index);

              if (ws_bind_d_ids[0].dplatform == "htx") {
                this.getVideo(index);
              } else if (ws_bind_d_ids[0].dplatform == "gbs") {
                console.log("gbs222");
                this.getVideoGBS(index);
              }
            }
          }
        });
      });
    },
    // 查询视频推流、拉流地址
    getVideo(i) {
      // this.ComeonlyWsid = [];
      const params = {
        ws_id: this.ws_id,
      };
      GetSiteVideo(params).then((res) => {
        if (res.code == 200) {
          // this.VideoCcomOnly = Array.from(new Set(this.VideoCcomOnly));
          // 关闭直播时使用
          this.ComeonlyWsid.push({
            ws_id: params.ws_id,
            com_only: res.data.com_only,
          });
          // console.log(this.ComeonlyWsid);
          // console.log("474", this.ComeonlyWsid);
          this.video = res.data;
          const url = res.data.video_url;
          const push_url = res.data.audio_url;
          this.video.stream_id = url.substring(url.lastIndexOf("/") + 1);
          this.video.push_stream_id = push_url.substring(
            push_url.lastIndexOf("/") + 1
          );
          // this.playerOptions.sources.src = this.video.push_stream_id;
          // 拉流
          this.videoPlayer(i);
          // 推流
          // this.talkStartTest();
          this.startPublish(
            this.stream_url + this.video.push_stream_id,
            new Audio()
          );

          // // 获取摄像头状态
          // this.videoStatus("cycle");
        } else {
          // this.$message.error(res.data);
          if (res.data == "摄像头状态为：4") {
            this.$message.error("自动挂断");
          }
        }
      });
    },

    // 每隔1秒检测一次摄像头状态
    checkStatus() {
      let that = this;
      if (that.cycleTime < that.cycleMax) {
        that.timer = setTimeout(() => {
          // that.videoStatus("cycle");
          that.cycleTime++;
        }, 1000);
      } else {
        that.$message.warn("直播查看超时");
        this.stopVideo();
      }
    },
    // 检测视频流状态
    videoStatus(type) {
      const params = {
        ws_id: this.ws_id,
        com_only: this.video.com_only,
      };
      CameraStatus(params).then((res) => {
        this.Video_Status = res.data;
        console.log("检测摄像头状态", this.Video_Status);
        if (res.data === "2") {
          window.clearTimeout(this.timer); // 清理定时器
          // 拉流
          // this.videoPlayer();
          //开始推流
          // this.talkStartTest();
          // console.log('this.video.push_stream_id', this.video.push_stream_id)
          // this.startPublish(
          //   this.stream_url + this.video.push_stream_id,
          //   new Audio()
          // );
        } else {
          if (type !== "cycle") {
            this.$message.error("视频加载中");
          } else {
            this.checkStatus();
          }
        }
      });
    },
    // flv拉流播放视频
    videoPlayer(i) {
      // var videoElement = document.getElementById("myVideo");
      // this.startPlay(this.stream_url + this.video.stream_id, videoElement);
      // this.record.forEach((item, i) => {
      var videoElement = document.getElementById("myVideo" + i);
      this.startPlay(this.stream_url + this.video.stream_id, videoElement);
      // });
      // if (flvjs.isSupported()) {
      if (false) {
        var videoElement = document.getElementById("myVideo");
        this.flvPlayer = flvjs.createPlayer({
          type: "flv",
          // url: 'https://dsm.wisecommey.com:8843/live?app=stream&stream=' + this.video.stream_id,
          url:
            // "https://dsm.wisecommey.com:8843/stream/" +
            "https://dsm.ccom.com.cn:8843/stream/" +
            this.video.stream_id +
            ".flv",
          // url: 'http://123.56.117.58:8080/live/123456',
        });
        this.flvPlayer.attachMediaElement(videoElement); //将播放实例注册到video节点
        this.flvPlayer.load();
        this.flvPlayer.play();
        this.flvPlayer.on(
          flvjs.Events.ERROR,
          (errorType, errorDetail, errorInfo) => {
            console.log("errorType:", errorType);
            console.log("errorDetail:", errorDetail);
            console.log("errorInfo:", errorInfo);
          }
        );
        this.flvPlayer.on(flvjs.Events.STATISTICS_INFO, function (info) {
          console.log(
            "STATISTICS_INFO:",
            info,
            videoElement.currentTime,
            videoElement.readyState
          );
          if (info.speed !== 0) {
            if (this.decodedFrames === info.decodedFrames) {
              this.count++;
            } else {
              this.count = 0;
            }
            if (this.count == 5) {
              console.log("卡住了", videoElement.currentTime);
              videoElement.currentTime = videoElement.buffered.end(0) - 0.2;
            }
            this.decodedFrames = info.decodedFrames;
          }
        });

        this.timeout = setInterval(() => {
          if (videoElement.buffered.length) {
            let end = videoElement.buffered.end(0);
            let diff = end - videoElement.currentTime;
            console.log("diff:", diff);
            if (diff >= 4.0) {
              videoElement.currentTime = videoElement.buffered.end(0) - 0.2;
              console.log("跳帧");
            } else if (diff >= 2.0) {
              if (videoElement.playbackRate === 1.0) {
                videoElement.playbackRate = 1.5; // 采用加速方式追帧
                console.log("追帧(1.5倍速)");
              }
            } else {
              if (videoElement.playbackRate !== 1.0) {
                videoElement.playbackRate = 1.0;
                console.log("1.0速度");
              }
            }
          }
        }, 2000);
      }
    },
    startPlay(url, video) {
      this.player = new SrsRtcPlayerAsync(video);
      // https://webrtc.org/getting-started/remote-streams
      // Optional callback, SDK will add track to stream.
      // sdk.ontrack = function (event) { console.log('Got track', event); sdk.stream.addTrack(event.track); };
      // For example: webrtc://r.ossrs.net/live/livestream
      this.player
        .play(url)
        .then(function (session) {
          // console.log("sessionid", session.simulator, session.sessionid);
        })
        .catch(function (reason) {
          this.player.close();
          console.error(reason);
        })
        .finally(() => {});
      // console.log(this.player);
    },
    // 关闭
    close() {
      window.clearTimeout(this.timer); // 清理定时器
      // this.offCall();
      // this.stopVideo();
      // console.log("close", this.ComeonlyWsid);
      this.ComeonlyWsid.forEach((item) => {
        console.log(item);
        this.video.com_only = item.com_only;
        this.ws_id = item.ws_id;
        this.offCall();
        this.stopVideo();
      });
      this.startPlayGBSIndex.forEach((el) => {
        this.VideoUnliveGBS(el);
      });

      // });
    },
    // 推流
    //方法未使用
    pushVideo() {
      window.navigator.webkitGetUserMedia(); //调用本地摄像头麦克风
    },
    stopVideo() {
      const params = {
        ws_id: this.ws_id,
        com_only: this.video.com_only,
      };
      // this.talkEndTest(); //断开通话
      if (this.publisher) {
        this.publisher.close();
      }
      if (this.flvPlayer) {
        clearInterval(this.timeout);
        this.flvPlayer.unload(); //取消数据流加载
        this.flvPlayer.detachMediaElement(); //将播放实例从节点中取出
        this.flvPlayer.destroy(); //销毁播放实例
        this.flvPlayer = null;
      }
      // StopSiteVideo(params)
      //   .then((res) => {
      //     // this.$message.success("断开视频成功");
      //   })
      //   .catch(() => {
      //     // this.$message.error("断开视频失败");
      //   });
      const url =
        urlConfig.baseUrl +
        "/dsmcore/api/worksite/camera/unvideo?" +
        "ws_id=" +
        this.ws_id +
        "&com_only=" +
        this.video.com_only;
      // navigator.sendBeacon(url, params);
      const formData = new FormData();
      formData.append("ws_id", this.ws_id);
      formData.append("com_only", this.video.com_only);
      fetch(url, {
        method: "POST",
        keepalive: true,
        headers: {
          token: sessionStorage.getItem("token"),
        },
      }).then((res) => {
        // console.log(res);
      });
    },
    handleCall() {
      this.voiceCall = !this.voiceCall;
      if (this.voiceCall) {
        this.onCall();
      } else {
        this.offCall();
      }
    },
    // 通话
    onCall() {
      const params = {
        ws_id: this.ws_id,
        com_only: this.video.com_only,
      };
      OnCall(params).then((res) => {
        console.log(res);
        if (res.code == "200" && res.data == "通话链接成功") {
          // this.record.forEach((item, i) => {
          //   var videoElement = document.getElementById("myVideo" + i);
          //   // videoElement.muted = true;
          // });
          // var videoElement = document.getElementById("myVideo");
          // videoElement.muted = true;
          this.voiceCall = true;
          this.$message.success("通话中");
        } else {
          this.$message.success("通话忙");
        }
      });
    },
    // 通话结束
    offCall() {
      const params = {
        ws_id: this.ws_id,
        com_only: this.video.com_only,
      };
      OffCall(params).then((res) => {
        // var videoElement = document.getElementById("myVideo");
        // this.record.forEach((el, i) => {
        //   var videoElement = document.getElementById("myVideo" + i);
        // });
        // videoElement.muted = false;
        this.voiceCall = false;
        // this.$message.success("通话结束");
      });
    },
    // 移动摄像机位置
    moveCamera(direction, directionCH) {
      const params = {
        ws_id: this.ws_id,
        com_only: this.video.com_only,
        direction: direction,
      };
      CameraMove(params).then((res) => {
        // this.$message.success("向" + directionCH + res.data);
      });
    },
    // 返回预置位
    reset() {
      // console.log(document.getElementById("myVideo").currentTime); //  获取当前播放时间
      // let ImgLoading = document.getElementById("ImgLoading");
      // ImgLoading.style.display = "block";
      const params = {
        ws_id: this.ws_id,
        com_only: this.video.com_only,
      };
      CameraReset(params).then((res) => {
        // this.$message.success(res.data);
        if (res.data == "摄像头复位设置成功") {
          this.$message.success("返回预置位成功");
        }
        // var myVideo = document.getElementById("myVideo");
        // var eventTester = (e) => {
        //   myVideo.addEventListener(e, (e) => {
        //     console.log("555");
        //   });
        // };
        // console.log(document.getElementById("myVideo").currentTime); //  获取当前播放时间
        // document.getElementById("myVideo").load();
        // eventTester("waiting"); //等待数据，并非错误
        // eventTester("seeking"); //寻找中
        // eventTester("canplay"); //可以播放，但中途可能因为加载而暂停
        // eventTester("timeupdate"); //播放时间改变
        // eventTester("error"); //请求数据时遇到错误  没用
        // eventTester("play"); //play()和autoplay开始播放时触发
        // eventTester("playing"); // playing：当视频在已因缓冲而暂停或停止后已就绪时触发
        // eventTester("pause"); //监听暂停
      });
    },
    handleRestart() {
      this.visible = true;
    },
    restartCancel() {
      this.visible = false;
    },
    // 重启摄像头
    restart() {
      this.confirmLoading = true;
      const params = {
        ws_id: this.ws_id,
        com_only: this.video.com_only,
      };
      CameraRestart(params)
        .then((res) => {
          this.$message.success("重启摄像头成功");
          this.confirmLoading = false;
          this.visible = false;
        })
        .catch(() => {
          this.$message.error("重启摄像头失败");
          this.confirmLoading = false;
        });
    },
    onPlayerPlay() {},
    onPlayerPause() {},
    //屏幕截图
    screenshot() {
      var canvas = document.getElementById("screenshot");
      if (!canvas.getContext) {
        alert("您的浏览器暂不支持canvas");
        return false;
      } else {
        var context = canvas.getContext("2d");
        // var video = document.getElementById("myVideo");
        this.record.forEach((item, i) => {
          var videoElement = document.getElementById("myVideo" + i);
        });
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        var mySrc = canvas.toDataURL("image/png");
      }
      var alink = document.createElement("a");
      alink.href = mySrc;
      alink.download = "jietu.png"; //图片名
      alink.click();
    },

    //对讲-语音推流
    init(rec) {
      this.soundrecord = rec;
    },
    talkStartTest1() {
      let that = this;
      navigator.getUserMedia =
        navigator.getUserMedia || navigator.webkitGetUserMedia;
      if (!navigator.getUserMedia) {
        alert("浏览器不支持音频输入");
      } else {
        navigator.getUserMedia(
          {
            audio: true,
          },
          function (mediaStream) {
            that.init(new that.Recorder(mediaStream, that));
            console.log("开始对讲");
            that.useWebSocket();
          },
          function (error) {
            console.log(error);
            switch (error.message || error.name) {
              case "PERMISSION_DENIED":
              case "PermissionDeniedError":
                console.info("用户拒绝提供信息。");
                break;
              case "NOT_SUPPORTED_ERROR":
              case "NotSupportedError":
                console.info("浏览器不支持硬件设备。");
                break;
              case "MANDATORY_UNSATISFIED_ERROR":
              case "MandatoryUnsatisfiedError":
                console.info("无法发现指定的硬件设备。");
                break;
              default:
                console.info(
                  "无法打开麦克风。异常信息:" + (error.code || error.name)
                );
                break;
            }
          }
        );
      }
    },
    // 新版使用方法
    talkStartTest() {
      // 老的浏览器可能根本没有实现 mediaDevices，所以我们可以先设置一个空的对象
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {};
      }
      // 一些浏览器部分支持 mediaDevices。我们不能直接给对象设置 getUserMedia
      // 因为这样可能会覆盖已有的属性。这里我们只会在没有getUserMedia属性的时候添加它。
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function (constraints) {
          // 首先，如果有getUserMedia的话，就获得它
          var getUserMedia =
            navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

          // 一些浏览器根本没实现它 - 那么就返回一个error到promise的reject来保持一个统一的接口
          if (!getUserMedia) {
            return Promise.reject(new Error("浏览器不支持getUserMedia"));
          }

          // 否则，为老的navigator.getUserMedia方法包裹一个Promise
          return new Promise(function (resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject);
          });
        };
      }

      let that = this;
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: false })
        .then(function (stream) {
          that.init(new that.Recorder(stream, that));
          console.log("开始对讲");
          that.useWebSocket();
        })
        .catch(function (err) {
          // console.log(err.name + ": " + err.message);
        });
    },
    // 关闭对讲
    talkEndTest() {
      if (this.ws) {
        this.ws.close();
        this.soundrecord.stop();
        console.log("关闭对讲以及WebSocket");
      }
    },
    // 推流
    startPublish(url, audio) {
      this.publisher = new SrsRtcPublisherAsync(audio);
      // console.log('startPublish', this.publisher)
      const publisher = this.publisher;
      this.publisher.pc.onicegatheringstatechange = function (event) {
        if (publisher.pc.iceGatheringState === "complete") {
          //  $('#acodecs').html(SrsRtcFormatSenders(sdk.pc.getSenders(), "audio"))
          //  $('#vcodecs').html(SrsRtcFormatSenders(sdk.pc.getSenders(), "video"))
        }
      };
      this.publisher
        .publish(url)
        .then(function (session) {
          console.log("sessionid", session.simulator, session.sessionid);
          publisher.mute();
        })
        .catch(function (reason) {
          // Throw by sdk.
          if (reason instanceof SrsError) {
            if (reason.name === "HttpsRequiredError") {
              alert(
                `WebRTC推流必须是HTTPS或者localhost：${reason.name} ${reason.message}`
              );
            } else {
              alert(`${reason.name} ${reason.message}`);
            }
          }
          // See https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia#exceptions
          if (reason instanceof DOMException) {
            if (reason.name === "NotFoundError") {
              // alert(
              //   `找不到麦克风和摄像头设备：getUserMedia ${reason.name} ${reason.message}`
              // );
            } else if (reason.name === "NotAllowedError") {
              alert(
                `你禁止了网页访问摄像头和麦克风：getUserMedia ${reason.name} ${reason.message}`
              );
            } else if (
              [
                "AbortError",
                "NotAllowedError",
                "NotFoundError",
                "NotReadableError",
                "OverconstrainedError",
                "SecurityError",
                "TypeError",
              ].includes(reason.name)
            ) {
              alert(`getUserMedia ${reason.name} ${reason.message}`);
            }
          }
          publisher.close();
          // console.error(reason);
        });
    },
    Recorder(stream, that) {
      var sampleBits = 16; //输出采样数位 8, 16
      var sampleRate = 16000; //输出采样率
      var context = new AudioContext();
      var audioInput = context.createMediaStreamSource(stream);
      var recorder = context.createScriptProcessor(4096, 1, 1);
      var audioData = {
        size: 0, //录音文件长度
        buffer: [], //录音缓存
        inputSampleRate: 48000, //输入采样率
        inputSampleBits: 16, //输入采样数位 8, 16
        outputSampleRate: sampleRate, //输出采样数位
        oututSampleBits: sampleBits, //输出采样率
        clear: function () {
          this.buffer = [];
          this.size = 0;
        },
        input: function (data) {
          this.buffer.push(new Float32Array(data));
          this.size += data.length;
        },
        compress: function () {
          //合并压缩
          //合并
          var data = new Float32Array(this.size);
          var offset = 0;
          for (var i = 0; i < this.buffer.length; i++) {
            data.set(this.buffer[i], offset);
            offset += this.buffer[i].length;
          }
          //压缩
          var compression = parseInt(
            this.inputSampleRate / this.outputSampleRate
          );
          var length = data.length / compression;
          var result = new Float32Array(length);
          var index = 0,
            j = 0;
          while (index < length) {
            result[index] = data[j];
            j += compression;
            index++;
          }
          return result;
        },
        encodePCM: function () {
          //这里不对采集到的数据进行其他格式处理，如有需要均交给服务器端处理。
          var sampleRate = Math.min(
            this.inputSampleRate,
            this.outputSampleRate
          );
          var sampleBits = Math.min(this.inputSampleBits, this.oututSampleBits);
          var bytes = this.compress();
          var dataLength = bytes.length * (sampleBits / 8);
          var buffer = new ArrayBuffer(dataLength);
          var data = new DataView(buffer);
          var offset = 0;
          for (var i = 0; i < bytes.length; i++, offset += 2) {
            var s = Math.max(-1, Math.min(1, bytes[i]));
            data.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
          }
          return new Blob([data]);
        },
      };

      var sendData = function () {
        //对以获取的数据进行处理(分包)
        var reader = new FileReader();
        reader.onload = (e) => {
          var outbuffer = e.target.result;
          var arr = new Int8Array(outbuffer);
          if (arr.length > 0) {
            var tmparr = new Int8Array(1024);
            var j = 0;
            for (var i = 0; i < arr.byteLength; i++) {
              tmparr[j++] = arr[i];
              if ((i + 1) % 1024 == 0) {
                if (that.ws.readyState == 1) {
                  console.log(tmparr);
                  that.ws.send(tmparr);
                }
                if (arr.byteLength - i - 1 >= 1024) {
                  tmparr = new Int8Array(1024);
                } else {
                  tmparr = new Int8Array(arr.byteLength - i - 1);
                }
                j = 0;
              }
              if (i + 1 == arr.byteLength && (i + 1) % 1024 != 0) {
                if (that.ws.readyState == 1) {
                  that.ws.send(tmparr);
                }
              }
            }
          }
        };
        reader.readAsArrayBuffer(audioData.encodePCM());
        audioData.clear(); //每次发送完成则清理掉旧数据
      };

      this.start = function () {
        audioInput.connect(recorder);
        recorder.connect(context.destination);
      };

      this.stop = function () {
        recorder.disconnect();
      };

      this.getBlob = function () {
        return audioData.encodePCM();
      };

      this.clear = function () {
        audioData.clear();
      };

      recorder.onaudioprocess = function (e) {
        var inputBuffer = e.inputBuffer.getChannelData(0);
        audioData.input(inputBuffer);
        sendData();
      };
    },
    useWebSocket() {
      let that = this;
      this.ws = new WebSocket(
        // "wss://dsm.wisecommey.com:8443/ws/asset/" + that.video.push_stream_id
        "wss://dsm.ccom.com.cn:8443/ws/asset/" + that.video.push_stream_id
      );
      // this.ws = new WebSocket("wss://dsm.wisecommey.com:8443/ws/asset/123");
      this.ws.binaryType = "arraybuffer"; //传输的是 ArrayBuffer 类型的数据
      this.ws.onopen = function () {
        console.log("握手成功");
        console.log(that.ws);
        if (that.ws.readyState == 1) {
          //ws进入连接状态，则每隔500毫秒发送一包数据
          that.soundrecord.start();
        }
      };
      this.ws.onmessage = function (msg) {
        console.info(msg);
      };
      this.ws.onerror = function (err) {
        console.info(err);
      };
    },
    // 全屏
    quanping() {
      // let quanping = document.getElementById("myVideo");
      // this.record.forEach((item, i) => {
      //   var quanping = document.getElementById("myVideo" + i);
      // });
      // quanping.webkitRequestFullScreen();
    },
    // 信号展示
    getvideoSignal() {
      const params = {
        ws_id: this.ws_id,
        type: "1",
      };
      videoSignal(params).then((res) => {
        console.log("type==1");
        if (res.data == "摄像机没有上线") {
          this.NoXinhao = true;
          this.setSign("0");
        }
        if (res.data == "向摄像头发送请求成功") {
          const params2 = {
            ws_id: this.ws_id,
            type: "2",
          };
          videoSignal(params2).then((res) => {
            // console.log(res);
            this.signalResData2 = res.data;
            console.log(
              "type==2",
              this.signalResData2,
              this.signalResData2 * 1
            );
            if (res.data == "未查询到该摄像头信号强度") {
              this.NoXinhao = true;
              this.setSign("0");
              // var tmpElement = document.getElementById("div_sign");
              // tmpElement.style.display = "none";
            } else {
              this.NoXinhao = false;
              this.getdbm(res.data);
              // var tmpElement = document.getElementById("div_sign");
              // tmpElement.style.display = "block";
              // console.log(res.data);
            }
          });
        }
      });
    },
    // 获取信号质量数值
    getdbm(dbm) {
      let count = "";
      if (dbm > -50) {
        count = 5;
      } else if (dbm > -90) {
        count = 4;
      } else if (dbm > -100) {
        count = 3;
      } else if (dbm > -110) {
        count = 2;
      } else if (dbm > -120) {
        count = 1;
      } else if (dbm < -120) {
        count = 0;
        this.NoXinhao = true;
      }
      // console.log(count);
      this.setSign(count);
    },
    setSign(count) {
      var tmpElement = document.getElementById("div_sign");
      for (var i = 0; i < 5; i++) {
        tmpElement.children[i].style.backgroundColor = "#FFF";
        if (i <= count - 1) {
          if (count < 2) {
            tmpElement.children[i].style.backgroundColor = "red"; //一格时为红色
          } else {
            tmpElement.children[i].style.backgroundColor = "#00FF00";
          }
        }
      }
    },
    // 监听鼠标移入移出出现取消样板工地的按钮
    ShowCanceSampleSiteBTN(i, isShow, isTrue) {
      let CanceSampleSiteBtn = document.getElementById(
        "CanceSampleSiteBtn" + i
      );
      // let setSampleSiteBtn = document.getElementById("setSampleSiteBtn" + i);
      CanceSampleSiteBtn.style.display = isShow;
      CanceSampleSiteBtn.style.backgroundColor = "#00BFBF";
      // setSampleSiteBtn.style.display = !isShow;
      // if (this.resData == "设置成功1") {
      //   //设置成功1 取消样板工地成功
      //   setSampleSiteBtn.style.display = "block";
      //   CanceSampleSiteBtn.style.display = "none";
      // } else if (this.resData == "设置成功2") {
      //   setSampleSiteBtn.style.display = "none";
      //   CanceSampleSiteBtn.style.display = "block";
      // }
    },
    // 取消样板工地
    CanceSampleSite(item, type, i) {
      console.log("取消样板工地", item);
      const params = {
        ws_ids: item,
        ws_model: type,
      };
      // 1：取消样板工地   2：设置样板工地
      SetSampleSite(params).then((res) => {
        console.log(res);
        if (res.data == "设置成功") {
          // this.resData = res.data + i;
          let Cance_ed = document.getElementById("Cance_ed" + i);
          Cance_ed.style.display = "block";
          // let CanceSampleSiteBtn = document.getElementById(
          //   "CanceSampleSiteBtn" + i
          // );
          // let setSampleSiteBtn = document.getElementById(
          //   "setSampleSiteBtn" + i
          // );
          // CanceSampleSiteBtn.style.display = "none";
          // setSampleSiteBtn.style.display = "block";
          // this.isSampleSite = false;
          setTimeout(() => {
            Cance_ed.style.display = "none";
          }, 2000);
        }
      });
    },
    //  gb28181设备
    // gb28181设备直播
    getVideoGBS(index) {
      let that = this;
      let params = {
        gb28181: this.liveImei,
      };
      GBVideoLive(params).then((res) => {
        if (res.code == 200) {
          that.startPlayGBS(res.data.rtcs, index);
          this.gbVideoValue = res.data;
          this.startPlayGBSIndex.push(index);
        }
      });
    },
    startPlayGBS(zlmsdpUrl, index) {
      console.log("startPlayGBS", zlmsdpUrl, index);
      this.player = new ZLMRTCClient.Endpoint({
        element: document.getElementById("myVideo" + index), // video 标签
        debug: true, // 是否打印日志
        zlmsdpUrl: zlmsdpUrl, //SDP消息交换的服务器URL 流地址  rtcs
        simulcast: false, //是否启用多路复用
        useCamera: false, //使用摄像头
        audioEnable: true, //使用麦克风
        videoEnable: true, //指定是否仅接收媒体流（即作为接收端）。如果为true，则不会发送本地媒体流到远端。
        recvOnly: true, //是否仅接收媒体流
        resolution: { w: 735, h: 425 },
        usedatachannel: false,
      });
      // console.log("aaaaa", this.player);

      // this.player.start();
      let jingyin = document.getElementById("myVideo" + index);
      jingyin.muted = true;
      // 监听事件

      this.player.on(
        ZLMRTCClient.Events.WEBRTC_ICE_CANDIDATE_ERROR,
        function (e) {
          // ICE 协商出错
          console.log("ICE 协商出错", e);
        }
      );

      this.player.on(
        ZLMRTCClient.Events.WEBRTC_ON_REMOTE_STREAMS,
        function (e) {
          //获取到了远端流，可以播放
          console.log("播放成功", e.streams);
        }
      );

      this.player.on(
        ZLMRTCClient.Events.WEBRTC_OFFER_ANWSER_EXCHANGE_FAILED,
        function (e) {
          // offer anwser 交换失败
          // console.log("offer anwser 交换失败", e);
          stop();
        }
      );

      this.player.on(ZLMRTCClient.Events.WEBRTC_ON_LOCAL_STREAM, function (s) {
        // 获取到了本地流

        document.getElementById("myVideo").srcObject = s;
        document.getElementById("myVideo").muted = true;
        console.log("获取到了本地流", s);

        //console.log('offer anwser 交换失败',e)
      });

      this.player.on(ZLMRTCClient.Events.CAPTURE_STREAM_FAILED, function (s) {
        // 获取本地流失败
        // console.log("获取本地流失败");
      });

      this.player.on(
        ZLMRTCClient.Events.WEBRTC_ON_CONNECTION_STATE_CHANGE,
        function (state) {
          // RTC 状态变化 ,详情参考 https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/connectionState
          // console.log("当前状态==>", state);
        }
      );

      this.player.on(
        ZLMRTCClient.Events.WEBRTC_ON_DATA_CHANNEL_OPEN,
        function (event) {
          // console.log("rtc datachannel 打开 :", event);
        }
      );

      this.player.on(
        ZLMRTCClient.Events.WEBRTC_ON_DATA_CHANNEL_MSG,
        function (event) {
          // console.log("rtc datachannel 消息 :", event.data);
          // document.getElementById("msgrecv").value = event.data;
        }
      );
      this.player.on(
        ZLMRTCClient.Events.WEBRTC_ON_DATA_CHANNEL_ERR,
        function (event) {
          // console.log("rtc datachannel 错误 :", event);
        }
      );
      this.player.on(
        ZLMRTCClient.Events.WEBRTC_ON_DATA_CHANNEL_CLOSE,
        function (event) {
          // console.log("rtc datachannel 关闭 :", event);
        }
      );
    },
    // 结束直播
    VideoUnliveGBS(index) {
      this.liveImeiList.forEach((el) => {
        const url =
          urlConfig.baseUrl + "/dsmcore/api/GB28181/unlive" + "gb28181=" + el;
        // navigator.sendBeacon(url, params);
        const formData = new FormData();
        formData.append("gb28181", el);

        fetch(url, {
          method: "POST",
          keepalive: true,
          headers: {
            token: sessionStorage.getItem("token"),
          },
        }).then((res) => {
          // console.log(res);
        });
        if (this.player) {
          var remote = document.getElementById("myVideo" + index);
          remote.pause();
          //关闭流
          this.player.close();
          this.player = null;
          // var local = document.getElementById("selfVideo");
          // local.srcObject = null;
          // local.load();
        }
      });

      // let params = {
      //   // gb28181: imei,
      //   gb28181: this.liveImei,
      // };
      // // GBVideoUnlive(params).then((res) => {
      // //   // console.log("GBVideoUnlive", res);
      // // });
    },
  },
  beforeDestroy() {
    // window.location.reload();
    if (this.player) {
      this.player.close();
    }
    if (this.publisher) {
      this.publisher.close();
    }
    this.close();

    // this.talkEndTest();
    clearInterval(this.getvideoSignalTimer);
    this.getvideoSignalTimer = null;
  },
  destroyed() {
    // window.removeEventListener("beforeunload");
  },
};
</script>

<style lang="less" scoped>
// 时间轴
video::-webkit-media-controls-timeline {
  display: none !important;
}
.bm-view {
  width: 100%;
  height: 200px;
}
.opt-button {
  position: absolute;
  bottom: 80px;
  // background-color: pink;
  width: 85%;
  // margin-left: 150px;
  margin-left: 90px;
  margin-top: 40px;
  button {
    margin-top: 5px;
    margin-right: 5px;
    i {
      padding-right: 5px;
    }
  }
}

.direction-button {
  border: solid 1px black;
  border-radius: 50%;
  width: 87px;
  // margin-left: 20px;
  margin-left: -10px;
  margin-top: 20px;
  // background-color: pink;
  .ant-col {
    height: 25px;
  }
  .dir-btn {
    cursor: pointer;
  }
  .ant-row {
    // padding-left: 5px;
    padding-left: 9px;
  }
}
.divScreenshot {
  display: none;
}

.xinhaoRed {
  color: red;
  font-size: 12px;
  margin: 0;
  padding: 0;
  margin-bottom: -25px;
}
@media screen and(min-width: 1400px) {
  .xinhaoRed {
    margin-bottom: -10px;
  }
}
.opt-button2 {
  .xinhao {
    // bottom: 10px;
    width: 30px;
    background-color: pink;
    // height: 30px;
    top: 100px;
    // margin-top: -560px;
    // left: 550px;
    margin-top: -75%;
    left: 90%;
    position: absolute;
  }
}

// .opt-button-xuncha {
//   .xinhao {
//     // bottom: 10px;
//     width: 30px;
//     // background-color: pink;
//     // height: 30px;
//     top: 100px;
//     margin-top: -560px;
//     left: 550px;
//     position: absolute;
//   }
// }
// 巡查页直播信号样式
.opt-button-xuncha {
  .xinhao {
    position: absolute;
    z-index: 99;
    left: 900px;
    top: -660px;
    height: 18px;
    display: flex;
    // background-color: pink;
  }

  .xinhaoRed {
    font-size: 12px;
    margin: 0;
    padding: 0;
    margin-top: -5px;
    margin-right: -10px;
    width: 10px;
    height: 10px;
  }
  // background-color: red;
  // width: 80%;
  // bottom: 100px;
}
.dir-patrol {
  position: absolute;
  // top: -200px;
  top: -60px;
  // left: 20px;
  // left: 10px;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding-top: 10px;
  border: 1px solid #aaa;
  .ant-col {
    height: 30px;
  }
  .dir-btn {
    cursor: pointer;
  }
}
.v-btn-out {
  background: none !important;
}
@media screen and(min-width: 1600px) {
  .opt_button2 {
    // background-color: red;
    // bottom: 320px;
    top: 580px;
    height: 30px;
    // width: 75%;
    width: 84%;
    .xinhao {
      // background-color: red;
      // bottom: 10px;
      width: 30px;
      // height: 30px;
      top: 100px;
      // margin-top: -93%;
      margin-top: -82%;
      // left: 560px;
      left: 91%;
      position: absolute;
    }
  }
  // 巡查页直播信号样式
  .xuncha_xinhao {
    .xinhao {
      position: absolute;
      z-index: 99;
      left: 100%;
      top: -590px;
      height: 18px;
      display: flex;
      // background-color: red;
    }

    .xinhaoRed {
      margin-top: -5px;
      font-size: 8px;
      margin-right: -11px;
    }
  }
}
@media screen and(max-width: 1600px) {
  .opt_button2 {
    // background-color: pink;
    // bottom: 320px;
    // top: 540px;
    top: 559px;
    // top: 56%;
    height: 30px;
    .xinhao {
      // bottom: 10px;
      width: 30px;
      // background-color: red;
      // height: 30px;
      top: 100px;
      // margin-top: -85%;
      margin-top: -82%;
      // left: 560px;
      // left: 94%;
      left: 95%;
      position: absolute;
    }
  }
  // 巡查页直播信号样式
  .xuncha_xinhao {
    .xinhao {
      position: absolute;
      z-index: 99;
      left: 100%;
      // top: -660px;
      top: -590px;
      height: 18px;
      display: flex;
      // background-color: pink;
    }

    // .xinhaoRed {
    //   // color: red;
    //   // font-size: 20px;
    //   // margin-top: 5px;
    // }
  }
}
@media screen and(max-width: 1366px) {
  .opt_button2 {
    // background-color: #aaa;
    // bottom: 360px;
    // top: 530px;
    top: 543px;
    // top: 59%;
    .xinhao {
      // bottom: 10px;
      width: 30px;
      // background-color: rgb(206, 130, 130);
      // height: 30px;
      top: 100px;
      // margin-top: -86%;
      margin-top: -81%;
      // left: 560px;
      left: 96%;
      position: absolute;
    }
  }
  // 巡查页直播信号样式
  .xuncha_xinhao {
    .xinhao {
      position: absolute;
      z-index: 99;
      // left: 80%;
      left: 100%;
      top: -660px;
      height: 18px;
      display: flex;
      // background-color: white;
    }

    // .xinhaoRed {
    //   // color: red;
    //   // font-size: 20px;
    //   // margin-top: 5px;
    // }
  }
}

.iconSize {
  font-size: 18px;
}
.v-btn {
  margin-bottom: 10px;
  // width: 35px;
  font-size: 12px;
  // background-color: pink;
}
.HistoryRecord {
  color: #1486e6;
  margin-top: 20px;
  cursor: pointer;
}
#ImgLoading {
  display: none;
}

.myVideo_out {
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  padding: 0;
  margin: 0;
  margin-top: 10px;
  width: 100%;
  height: 70%;
  .myVideo_out1 {
    width: 20%;
    // width: 100%;
    // margin: 10px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    // height: 70%;
    height: 65%;
    position: relative;
    margin-bottom: 3%;
    // margin-bottom: 70px;
  }

  // .myVideo {
  //   // display: flex;
  // }
  .myVideo_out3x5 {
    position: relative;
    width: 20%;
    // margin: 10px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    height: 35%;
    margin-bottom: 3%;
  }
  .myVideo_out2x4 {
    position: relative;
    width: 25%;
    // margin: 10px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 3%;
    // margin-bottom: 30px;
    height: 65%;
  }
  .myVideo_out2x6 {
    position: relative;
    width: 16%;
    // margin: 10px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    height: 65%;
    margin-bottom: 3%;
  }
}
.controlBtn {
  top: 0;
  right: 3%;
  color: white;
  width: 45%;
  z-index: 9;
  position: absolute;
  cursor: pointer;
}
.v-box-info {
  // position: absolute;
  // bottom: -11%;
  width: 97%;
  padding: 0 10px 5px 10px;
  text-align: left;
  background-color: #8ac6c3;
  z-index: 1;
  // height: 14%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  // margin-bottom: 10px;
}
.v-box-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.w_name_jl_left {
  margin-left: 10px;
}
.Cance_ed {
  position: absolute;
  left: 40%;
  top: 50%;
  // padding: 10px 20px;
  // background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  z-index: 100;
  // display: none;
}
</style>
