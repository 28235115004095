import request from "@/utils/request";

const api = {
  // getScreenWorkList: "/dsmcore/api/screen/worklist",
  getScreenWorkList: "/dsmcore/api/screen/worksitecount", // 数据看板工地统计
  getScreenAttendance: "/dsmcore/api/screen/attendance", // 数据看板员工出勤
  getScreenWorkSiteNothing: "/dsmcore/api/screen/worksitenothing", // 数据看板无人施工
  // getScreenDeviceBinding: "/dsmcore/api/screen/devicebinding", //  数据看板设备概况
  getScreenDeviceBinding: "/dsmcore/api/screen/worksitebind", //  数据看板工地绑定率
  // getScreenDeviceOnoff: "/dsmcore/api/screen/deviceonoff", //数据看板设备使用率
  getScreenDeviceOnoff: "/dsmcore/api/screen/deviceuse", //数据看板设备使用率
  getScreenDeviceDeviceonline: "/dsmcore/api/screen/deviceonline", //数据看板设备在线率
  getScreenWorksiteImage: "/dsmcore/api/screen/worksiteonwork", // 数据看板在施工排行
  getScreenWorkAttendance: "/dsmcore/api/screen/attendance", // 数据看板工地打卡
  // getScreenWorkList: "/dsmcore/api/screen/worklist", // 数据看板在施工工地
  getScreenWorksiteNewImage: "/dsmcore/api/screen/sitedetails", //可视化案场获取工地最新的快照
  getScreenEventcount: "/dsmcore/api/screen/eventcount", //可数据看板报警事件管理
};

export function GetScreenWorkList(parameter) {
  return request({
    url: api.getScreenWorkList,
    method: "post",
    params: parameter,
  });
}

export function GetScreenAttendance() {
  return request({
    url: api.getScreenAttendance,
    method: "post",
  });
}
export function GetScreenWorkSiteNothing(parameter) {
  return request({
    url: api.getScreenWorkSiteNothing,
    method: "post",
    params: parameter,
  });
}
export function GetScreenDeviceBinding(parameter) {
  return request({
    url: api.getScreenDeviceBinding,
    method: "post",
    params: parameter,
  });
}
export function GetScreenDeviceOnoff(parameter) {
  return request({
    url: api.getScreenDeviceOnoff,
    method: "post",
    params: parameter,
  });
}

export function getScreenDeviceDeviceonline(parameter) {
  return request({
    url: api.getScreenDeviceDeviceonline,
    method: "post",
    params: parameter,
  });
}
export function GetScreenWorksiteImage() {
  return request({
    url: api.getScreenWorksiteImage,
    method: "post",
  });
}
export function GetScreenWorksiteNewImage(parameter) {
  return request({
    url: api.getScreenWorksiteNewImage,
    method: "post",
    params: parameter,
  });
}
export function getScreenWorkAttendance(parameter) {
  return request({
    url: api.getScreenWorkAttendance,
    method: "post",
    params: parameter,
  });
}
export function getScreenEventcount(parameter) {
  return request({
    url: api.getScreenEventcount,
    method: "post",
    params: parameter,
  });
}
// export function GetScreenWorkList() {
//   return request({
//     url: api.getScreenWorkList,
//     method: "post",
//   });
// }
