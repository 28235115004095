<template>
  <div class="out">
    <div class="Preview_out">
      <div class="Preview_flex">
        <div class="Preview">
          <CasePreview
            :record="record"
            :show_reviewNum="show_reviewNum"
            :queryParam2="queryParam2"
            ref="close"
          ></CasePreview>
        </div>
      </div>
    </div>
    <!-- 按钮 -->
    <div class="AllBtn">
      <!--直播快照切换 -->
      <div class="cameraIcon" @click="showPreview()">
        <span><a-icon type="camera" theme="filled" /></span>
      </div>
      <!-- 分页 -->
      <a-pagination
        simple
        :default-current="1"
        :total="total"
        @change="change"
        class="pagination"
      />
      <!-- 暂停自动轮播 -->
      <div class="autoplay">
        <div
          class="stop_autoplay"
          v-show="start_autoplayShow"
          @click="AutoPlay('start')"
        >
          <a-icon type="play-circle" theme="filled" />
        </div>
        <div
          class="stop_autoplay"
          v-show="!start_autoplayShow"
          @click="AutoPlay('stop')"
        >
          <a-icon type="pause-circle" theme="filled" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { GetServiceList } from "@/api/site"; //获取工地列表
import CasePreview from "./CasePreview.vue";
import { urlConfig } from "@/config/config";

export default {
  name: "Preview",
  components: {
    CasePreview,
  },
  props: {
    queryParam2: {
      type: Object,
      default: {},
    },
    showPreviewNum: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      autoplay: false, //自动轮播
      pathUrl:
        urlConfig.baseUrl + "/files/picture/snapshot/snapshotpicture?filePath=",
      start_autoplayShow: true,
      startOrStop_outoPlay: null, //开始或停止自动播放
      total: 500,
      NewImageShow_NewImage: true,
      WsId: [],
      record: [],
      // pageSize: 10,
      show_reviewNum: "",
    };
  },
  created() {
    // this.GetNewImage();
  },
  mounted() {
    this.GetNewImage();
    window.onunload = function (e) {
      clearInterval(this.startOrStop_outoPlay);
      this.startOrStop_outoPlay = null;
    };
    this.show_reviewNum = this.showPreviewNum;
  },
  watch: {
    showPreviewNum(NV, OV) {
      console.log(NV);
      this.show_reviewNum = NV;
      this.GetNewImage();
    },
    immediate: true,
  },
  methods: {
    // 请求
    GetNewImage() {
      this.WsId = [];
      let orderParam = [
        "ws_name",
        "w_name",
        "w_gj_name",
        "bc_name",
        "nowork",
        "ws_bind_time_start",
        "ws_bind_time_end",
        "ws_bind_status",
        "ws_status",
        "ws_service_type",
        "ws_attendance",
        "ws_only",
        "d_status",
        "ws_dispatch",
        "bc_id",
        "cs_id",
        "d_imei",
        "pageNo",
        "pageSize",
      ];
      const params = {
        ...this.queryParam2,
        verify: this.$verify(this.queryParam2, orderParam),
      };
      GetServiceList(params).then((res) => {
        // console.log(res.data.data);
        this.record = res.data.data;
        this.total = res.data.sum;
        // console.log(this.record);
      });
    },
    // 可以写个定时器，点击自动播放的时候写上定时器，调用next()切换到下一面板的方法，
    // 点击取消自动播放的时候清除定时器
    AutoPlay(text) {
      this.start_autoplayShow = !this.start_autoplayShow;
      if (text == "start") {
        console.log("start");
        //   调用下一页的方法
        this.startOrStop_outoPlay = setInterval(() => {
          // this.pageNo = this.pageNo + 1;
          this.queryParam2.pageNo = this.queryParam2.pageNo + 1;
          // this.GetNewImage();
          this.change(this.queryParam2.pageNo);
        }, 240000); //四分钟换下一页，换一次结束切换
        // }, 2000); //2s换下一页，换一次结束切换
      }
      setTimeout(() => {
        clearInterval(this.startOrStop_outoPlay);
        this.startOrStop_outoPlay = null;
        this.NewImageShow_NewImage = true;
        console.log("this.NewImageShow_NewImage", this.NewImageShow_NewImage);
        this.$emit("NewImageShow_NewImage", this.NewImageShow_NewImage);
      }, 480000);
      // }, 3000);
      if (text == "stop") {
        clearInterval(this.startOrStop_outoPlay);
        this.startOrStop_outoPlay = null;
        console.log("stop");
      }
    },
    change(page, pageSize) {
      // console.log(page, pageSize);

      this.queryParam2.pageNo = page;
      // this.pageSize=pageSize
      this.GetNewImage();
    },
    showPreview() {
      this.NewImageShow_NewImage = true;
      // this.$refs.close.close();
      this.$emit("NewImageShow_NewImage", this.NewImageShow_NewImage);
    },
  },
  beforeDestroy() {
    clearInterval(this.startOrStop_outoPlay);
    this.startOrStop_outoPlay = null;
  },
};
</script>
<style scoped lang="less">
/* For demo */
.ant-carousel {
  .slick-slide {
    text-align: center;
    height: 160px;
    line-height: 160px;
    background: #364d79;
    overflow: hidden;
  }
  .slick-slide h3 {
    color: #fff;
  }
  .custom-slick-arrow {
    width: 25px;
    height: 25px;
    font-size: 29px;
    color: #fff;
    background-color: rgba(31, 45, 61, 0.11);
    opacity: 0.3;
  }
  .custom-slick-arrow:before {
    display: none;
  }
  .custom-slick-arrow:hover {
    opacity: 0.5;
  }
}

/deep/.slick-dots,
.slick-dots-bottom {
  display: none !important;
}
/deep/ .ant-carousel .custom-slick-arrow {
  z-index: 9;
}
/deep/.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  top: 100%;
}
/deep/.ant-carousel .slick-prev {
  left: 45% !important;
}
/deep/.ant-carousel .slick-next {
  left: 55% !important;
}
.out {
  width: 100%;
  height: 95%;
  position: relative;
  //   background-color: #fff;
  .Preview_out {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    // width: 100%;
    height: 96%;
    // background-color: #994848;
    .Preview_flex {
      display: flex;

      // margin-left: 3%;
      // margin-left: 10px;
      // width: 97%;
      width: 99%;
      height: 100%;
    }
    .Preview {
      display: flex;
      // width: 18%;
      // width: 95%;
      width: 100%;
      //   height: 350px;
      // height: 380px;
      height: 100%;
      // margin: 10px 0px 10px 10px
      margin: 10px 0px 10px 10px

      // background-color: pink;
    }
  }
  .stop_autoplay {
    // position: absolute;
    // top: 760px;
    // top: 820px;
    // left: 50%;
    // z-index: 99;
    //   background-color: yellow;
    color: #fff;
    // opacity: 0.3;
    font-size: 29px;
    margin-left: 50%;
  }
}

@media screen and(max-width: 1046px) {
  // .stop_autoplay {
  //   // top: 87%;
  // }
}
.imgUrl {
  width: 100%;
  height: 100%;
}
.pagination {
  margin-left: 10px;
  margin-top: 10px;
}
/deep/ .ant-pagination-simple-pager {
  display: none;
}

.AllBtn {
  display: flex;
  // width: 15%;
  width: 45%;
  height: 5%;
  margin-left: 45%;
  // background-color: pink;
  color: white;
  margin-top: 30px;
// bottom: 0;
// position: absolute;
  .cameraIcon {
    font-size: 29px;
    // margin-left: 25%;
  }
  .cameraIcon,
  .pagination,
  .autoplay {
    display: none;
  }
}
.AllBtn:hover {
  .cameraIcon,
  .pagination,
  .autoplay {
    display: block;
  }
}
</style>
