<template>
  <div :id="'div' + videoId">
    <video
      autoplay
      controls
      width="100%"
      height="300px"
      :id="videoId"
      muted="true"
    ></video>
  </div>
</template>
<script>
import {
  GetSiteVideo,
  CameraStatus,
  StopSiteVideo,
  OnCall,
  OffCall,
  CameraMove,
  CameraReset,
  CameraRestart,
} from "@/api/video";
import flvjs from "flv.js";
import videojs from "video.js";

export default {
  name: "LiveBroadcast",

  props: {
    site: {},
    videoId: "",
  },
  data() {
    return {
      ws_id: "",
      video: {
        stream_id: "",
        push_stream_id: "",
        com_only: "",
      },
      timer: null,
      cycleMax: 10,
      cycleTime: 1,
      propsSite: {},
    };
  },
  mounted() {
    //绑定页面关闭事件
    window.addEventListener("beforeunload", (e) => this.beforeunloadHandler(e));
    // this.ws_id = this.site.key || ""
    // this.getVideo();
  },
  destroyed() {
    window.removeEventListener("beforeunload", this.beforeunloadHandler);
  },
  methods: {
    // 查询视频推流、拉流地址
    getVideo() {
      const params = {
        ws_id: this.ws_id,
      };
      GetSiteVideo(params).then((res) => {
        if (res.code == 200) {
          this.video = res.data;
          const url = res.data.video_url;
          const push_url = res.data.audio_url;
          this.video.stream_id = url.substring(url.lastIndexOf("/") + 1);
          this.video.push_stream_id = push_url.substring(
            push_url.lastIndexOf("/") + 1
          );
          //直接销毁实例不获取状态
          // this.chanegUrl();
          this.videoStatus("cycle");
        } else {
          this.$message.error("视频查看失败");
          this.notplay();
        }
      });
    },
    // 每隔1秒检测一次状态
    checkStatus() {
      let that = this;
      if (that.cycleTime < that.cycleMax) {
        that.timer = setTimeout(() => {
          that.videoStatus("cycle");
          that.cycleTime++;
        }, 1000);
      } else {
        this.cycleTime = 1;
        that.$message.warn("直播查看超时");
        this.stopVideo();
        this.notplay();
      }
    },
    // 检测视频流状态
    videoStatus(type) {
      const params = {
        ws_id: this.ws_id,
        com_only: this.video.com_only,
      };
      CameraStatus(params).then((res) => {
        if (res.data === "2") {
          window.clearTimeout(this.timer); // 清理定时器
          this.chanegUrl();
        } else {
          this.checkStatus();
        }
      });
    },
    // flv拉流播放视频
    videoPlayer() {
      this.cycleTime = 1;
      if (flvjs.isSupported()) {
        console.log(this.videoId);
        let videoElement = document.querySelector(
          "#div" + this.videoId + " video"
        );
        //var videoElement = document.getElementById(this.videoId);
        console.log(videoElement);
        var flvPlayer = flvjs.createPlayer({
          type: "flv",
          url:
            // "https://dsm.wisecommey.com:8843/live?app=stream&stream=" +
            "https://dsm.ccom.com.cn:8843/live?app=stream&stream=" +
            this.video.stream_id,
        });
        flvPlayer.attachMediaElement(videoElement);
        flvPlayer.load();
        flvPlayer.play();
      }
    },
    // 断开视频
    stopVideo() {
      if (this.ws_id != "") {
        console.log("断流操作" + this.ws_id);
        window.clearTimeout(this.timer);
        const params = {
          ws_id: this.ws_id,
          com_only: this.video.com_only,
        };
        StopSiteVideo(params)
          .then((res) => {
            this.$message.success("断开视频成功");
          })
          .catch(() => {
            this.$message.error("断开视频失败");
          });
      }
    },
    // 工地直播初始化
    startVideo() {
      // console.log("第"+this.videoId+'窗口')
      this.ws_id = this.propsSite.key || "";
      this.getVideo();
    },
    // 动态传值
    propsData(data) {
      this.propsSite = data;
    },
    //销毁原实例
    chanegUrl() {
      // 新创建一个video实例
      let parentDiv = document.getElementById("div" + this.videoId);
      while (parentDiv.hasChildNodes()) {
        parentDiv.removeChild(parentDiv.firstChild);
      }
      // let video = document.createElement("video"); // 创建一个video标签
      // // 设置video标签属性
      // video.setAttribute("autoplay", "true");
      // video.setAttribute("controls", "true");
      // video.setAttribute("muted", "true");
      // video.setAttribute("width", "100%");
      // video.setAttribute("height", "300px");
      // parentDiv.appendChild(video); // 把创建的节点添加到父类div中；

      let video =
        '<video autoplay controls width="100%" height="300px" muted="true"></video>';
      parentDiv.innerHTML = video;

      // 调用播放视频方法
      setTimeout(this.videoPlayer(), 1000);
    },
    //视频无法播放
    notplay() {
      // 新创建一个video实例
      let parentDiv = document.getElementById("div" + this.videoId);
      while (parentDiv.hasChildNodes()) {
        parentDiv.removeChild(parentDiv.firstChild);
      }
      let div =
        "<div style='display:block;width:100%;height:300px;background-color: #333333;margin-bottom: 5px;'>";
      div +=
        "<p style='line-height:300px;font-size:20px;font-weight:bold;color:#fff'>无法播放</p>";
      div += "</div>";
      parentDiv.innerHTML = div; // 把创建的节点添加到父类div中；
    },
    //窗口关闭后
    beforeunloadHandler(e) {
      this.stopVideo();
    },
  },
  destroyed() {
    window.clearTimeout(this.timer);
  },
};
</script>
