<template>
  <div class="out">
    <div class="Preview_out">
      <div :class="Preview_flex">
        <div :class="PreviewName" v-for="index in imgUrl" :key="index.s_url">
          <img :src="`${pathUrl}` + index.s_url" class="imgUrl" />
        </div>
        <div :class="PreviewName" v-for="index in imgUrl2" :key="index.s_url">
          <img :src="`${pathUrl}` + index.s_url" class="imgUrl" />
        </div>
      </div>
      <!-- <div :class="Preview_flex">
        <div :class="PreviewName" v-for="(index, item) in imgUrl2" :key="item">
          <img :src="`${pathUrl}` + index.s_url" class="imgUrl" />
        </div>
      </div> -->
    </div>
    <!-- 按钮 -->
    <div class="AllBtn">
      <!--直播快照切换 -->
      <div class="cameraIcon" @click="showPreview()">
        <!-- <span><a-icon type="video-camera" /></span> -->
        <span><a-icon type="video-camera" theme="filled" /></span>
        <!-- <span><a-icon type="camera" theme="filled" /></span> -->
      </div>
      <!-- 分页 -->
      <a-pagination
        simple
        :default-current="1"
        :total="total"
        @change="change"
        class="pagination"
      />
      <!-- 暂停自动轮播 -->
      <div class="autoplay">
        <div
          class="stop_autoplay"
          v-show="start_autoplayShow"
          @click="AutoPlay('start')"
        >
          <!-- <a-icon type="play-circle" /> -->
          <a-icon type="play-circle" theme="filled" />
        </div>
        <div
          class="stop_autoplay"
          v-show="!start_autoplayShow"
          @click="AutoPlay('stop')"
        >
          <!-- <a-icon type="pause-circle" /> -->
          <a-icon type="pause-circle" theme="filled" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { GetScreenWorksiteNewImage } from "@/api/screen"; //可视化案场获取工地最新快照
import { urlConfig } from "@/config/config";
export default {
  name: "NewImage",
  props: {
    queryParam: {
      //   type: Object,
      default: {
        ws_id: "",
        bc_id: "",
        pageNo: 1,
        pageSize: 10,
      },
    },
    showPreviewNum: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      autoplay: false, //自动轮播
      pathUrl:
        urlConfig.baseUrl + "/files/picture/snapshot/snapshotpicture?filePath=",
      imgUrl: [{}],
      imgUrl2: [{}],
      start_autoplayShow: true,
      startOrStop_outoPlay: null, //开始或停止自动播放
      pageSize: 10,
      pageNo: 1,
      total: 500,
      NewImageShow_NewImage: false,
      PreviewName: "",
      Preview_flex: "Preview_flex",
    };
  },
  created() {
    // console.log(this.queryParam);
    this.GetNewImage();
  },
  watch: {
    showPreviewNum(NV, OV) {
      if (NV == "2x5") {
        this.queryParam.pageSize = 10;
        this.PreviewName = "Preview";
        this.Preview_flex = "Preview_flex";
        this.GetNewImage();
      }
      if (NV == "2x4") {
        this.queryParam.pageSize = 8;
        this.PreviewName = "Preview2x4";
        this.Preview_flex = "Preview_flex";
        this.GetNewImage();
      }
      if (NV == "2x6") {
        this.queryParam.pageSize = 12;
        this.PreviewName = "Preview2x6";
        this.Preview_flex = "Preview_flex";
        this.GetNewImage();
      }
      if (NV == "3x5") {
        this.queryParam.pageSize = 15;
        this.PreviewName = "Preview3x5";
        this.Preview_flex = "Preview_flex3x5";
        this.GetNewImage();
      }
    },
    immediate: true,
  },
  methods: {
    // 请求快照接口
    GetNewImage() {
      let order = ["bc_id", "pageNo", "pageSize"];
      const params = {
        ...this.queryParam,

        verify: this.$verify(this.queryParam, order),
      };
      GetScreenWorksiteNewImage(params).then((res) => {
        this.imgUrl = res.data.data.slice(0, 5);
        this.imgUrl2 = res.data.data.slice(5, 11);
        this.total = res.data.sum;
      });
    },
    // 可以写个定时器，点击自动播放的时候写上定时器，调用next()切换到下一面板的方法，
    // 点击取消自动播放的时候清除定时器
    AutoPlay(text) {
      this.start_autoplayShow = !this.start_autoplayShow;
      if (text == "start") {
        console.log("start");
        //   调用下一页的方法
        this.startOrStop_outoPlay = setInterval(() => {
          // this.pageNo = this.pageNo + 1;
          this.queryParam.pageNo = this.queryParam.pageNo + 1;
          this.change(this.queryParam.pageNo);
          // console.log(this.pageNo);
          // this.GetNewImage();
        }, 60000);
        // }, 2000);
      }
      if (text == "stop") {
        clearInterval(this.startOrStop_outoPlay);
        this.startOrStop_outoPlay = null;
        console.log("stop");
      }
    },
    change(page, pageSize) {
      console.log(page, pageSize);
      // this.pageNo = page;
      this.queryParam.pageNo = page;
      // this.pageSize=pageSize
      this.GetNewImage();
    },
    showPreview() {
      this.NewImageShow_NewImage = false;
      this.$emit("NewImageShow_NewImage", this.NewImageShow_NewImage);
    },
  },
};
</script>
<style scoped lang="less">
/* For demo */
.ant-carousel {
  .slick-slide {
    text-align: center;
    height: 160px;
    line-height: 160px;
    background: #364d79;
    overflow: hidden;
  }
  .slick-slide h3 {
    color: #fff;
  }
  .custom-slick-arrow {
    width: 25px;
    height: 25px;
    font-size: 29px;
    color: #fff;
    background-color: rgba(31, 45, 61, 0.11);
    opacity: 0.3;
  }
  .custom-slick-arrow:before {
    display: none;
  }
  .custom-slick-arrow:hover {
    opacity: 0.5;
  }
}

/deep/.slick-dots,
.slick-dots-bottom {
  display: none !important;
}
/deep/ .ant-carousel .custom-slick-arrow {
  z-index: 9;
}
/deep/.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  top: 100%;
}
/deep/.ant-carousel .slick-prev {
  left: 45% !important;
}
/deep/.ant-carousel .slick-next {
  left: 55% !important;
}
/deep/ .ant-pagination-disabled a {
  color: rgba(0, 0, 0, 1);
}
.out {
  width: 100%;
  height: 95%;
  position: relative;
  //   background-color: #fff;
  .Preview_out {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    width: 100%;
    height: 96%;
    //   background-color: #994848;
    .Preview_flex {
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-between;
      // margin-left: 17px;
      height: 97%;
      width: 97%;
      // background-color: pink;
      margin-bottom: 1%;
    }
    .Preview {
      width: 19%;
      // height: 350px;
      // height: 380px;
      height: 50%;
      margin: 10px 0px 10px 15px
      // margin: 10px;
    }
    .Preview2x4 {
      width: 24%;
      height: 50%;
      // margin: 10px;
       margin: 10px 0px 10px 15px;
      display: flex;
      flex-wrap: wrap;
    }
    .Preview2x6 {
      width: 15%;
      height: 50%;
      // margin: 10px;
      margin: 10px 10px 10px 15px;

    }
    .Preview3x5 {
      width: 19%;
      // height: 33%;
      height: 100%;
      // margin: 10px;
      margin: 10px 0px 10px 15px;

    }
    .Preview_flex3x5 {
      // height: 97%;
      height: 30%;
      width: 97%;
      display: flex;
      flex-wrap: wrap;
    }
  }
  .stop_autoplay {
    // position: absolute;
    // top: 760px;
    // top: 820px;
    // left: 50%;
    // z-index: 99;
    //   background-color: yellow;
    color: #fff;
    // opacity: 0.3;
    font-size: 29px;
    margin-left: 50%;
  }
}

@media screen and(max-width: 1046px) {
  // .stop_autoplay {
  //   // top: 87%;
  // }
}
.imgUrl {
  width: 100%;
  height: 100%;
  margin-top: 10px;
}
.pagination {
  margin-left: 10px;
  margin-top: 10px;
}
/deep/ .ant-pagination-simple-pager {
  display: none;
}
.AllBtn {
  display: flex;
  // width: 15%;
  width: 45%;
  height: 5%;
  margin-left: 45%;
  // background-color: pink;
  color: white;
  margin-top: 20px;
  .cameraIcon {
    font-size: 29px;
    // margin-left: 25%;
  }
  .cameraIcon,
  .pagination,
  .autoplay {
    display: none;
  }
}
.AllBtn:hover {
  .cameraIcon,
  .pagination,
  .autoplay {
    display: block;
  }
}
</style>
