<template>
  <div class="v-container">
    <!-- v-show="showOnePreview" -->
    <div class="sub-container">
      <!-- 顶部标题 -->
      <div class="v-title">
        <div class="v-title-left" @click="showTimeClick">
          <span v-show="showTime"> {{ nowTime }}</span>
        </div>
        <!-- <div :class="{ editing: isChecked }">可视化案场</div> -->
        <div id="test" class="test" :class="{ editing: isChecked }">
          <div class="view">
            <label @dblclick="dbTest()">{{ TitleName }}</label>
          </div>
          <input
            v-myfoucs="isChecked"
            class="edit"
            type="text"
            v-model="inputStr"
            @blur="inputStred()"
            @keyup.13="inputStred()"
          />
        </div>

        <div class="v-title-right2">
          <!-- 单屏和多屏切换 -->
          <div class="Split" v-if="isShowSelect">
            <!-- <span @click="isshowOnePreview">单屏</span> -->
            <a-select
              default-value="分屏设置"
              style="width: 120px"
              @change="handleChange"
            >
              <a-select-option v-for="item in PreviewNum" :key="item">
                {{ item }}
              </a-select-option>
            </a-select>
          </div>
          <div>
            <span @click="clickFun" class="quanping">
              <!-- <i class="iconfont icon-quanping"> -->
              <i class="iconfont icon-quanping">
                {{ isFullFlag ? "退出全屏" : "全屏" }}
              </i></span
            >
          </div>
        </div>
      </div>
      <!-- 数据 -->

      <!-- 没有样板工地时显示 -->
      <!-- <div v-show="isNoCaseShow">
        <no-case @changeList="changeList"></no-case>
      </div> -->
    </div>
    <div v-if="!showOnePreview">
      <!-- <SingleScreen :showMorePreview="showMorePreview" /> -->
      <SingleScreen :showOnePreview="showOnePreview" />
      <!-- @showOnePreview="isshowOnePreview" -->
    </div>
    <div class="sub-buttom" v-else>
      <!-- <div class="sub-buttom"> -->
      <!-- 快照轮播 -->
      <NewImage
        :queryParam="queryParam"
        v-if="NewImageShow"
        @NewImageShow_NewImage="NewImageShow_NewImage"
        :showPreviewNum="showPreviewNum"
      ></NewImage>
      <!-- 直播轮播 先显示直播      "-->
      <Preview
        :queryParam2="queryParam"
        v-if="!NewImageShow"
        @NewImageShow_NewImage="NewImageShow_NewImage"
        :showPreviewNum="showPreviewNum"
      ></Preview>
    </div>
  </div>
</template>
<script>
import SitePreview from "@/views/site/SitePreview.vue";
import LiveBroadcast from "../patrol/LiveBroadcast.vue";
import PatrolCarousel from "./PatrolCarousel.vue";
import screenfull from "screenfull";
import { requestImage } from "@/utils/fileRequest";
import { urlConfig } from "@/config/config";
import NewImage from "./NewImage.vue";
import Preview from "./Preview.vue";
import SingleScreen from "./SingleScreen.vue";
export default {
  components: {
    SitePreview,
    LiveBroadcast,
    PatrolCarousel,
    NewImage,
    Preview,
    SingleScreen,
  },
  data() {
    return {
      TitleName: "可视化案场",
      inputStr: "",
      isChecked: false,
      isFullFlag: false,
      current: 1,
      total: 0,
      componentKey: 50,
      queryParam: {
        ws_id: "",
        bc_id: "",
        ws_bind_status: "2",
        ws_status: "1",
        d_status: "1",
        ws_model: "1",
        pageNo: 1,
        pageSize: 10,
        // pageSize: 1,
      },
      // NoCase是否显示
      isShow: true,
      isNoCaseShow: false,
      ws_id: "",
      bc_id: "",
      nowTime: "", // 当前日期时间
      record: {
        ws_name: "",
        ws_id: "",
        w_power: "",
      },
      // 图片地址
      pathUrl: "",
      // urlConfig.baseUrl + "/files/picture/snapshot/snapshotpicture?filePath=",
      pageTypecaseField: "2",
      NewImageShow: false, //先显示直播
      // NewImageShow: true, //先显示快照
      // showOnePreview: true, //先显示多屏
      showOnePreview: false, //先显示单屏
      showMorePreview: false,
      // PreviewNum: ["单屏轮播", "2x4分屏", "2x5分屏", "2x6分屏", "3x5分屏"],
      PreviewNum: ["单屏轮播", "2x4分屏", "2x5分屏"],

      showPreviewNum: "",
      uidID: "",
      isShowSelect: true,
      showTime: true,
    };
  },

  created() {
    let u_id = JSON.parse(localStorage.getItem("userMsg"));
    // console.log(u_id.user.id);
    this.uidID = u_id.user.id;
    if (this.uidID == "57" || this.uidID == "28") {
      this.showOnePreview = true;
      this.showPreviewNum = "2x5";
      this.isShowSelect = false;
    }
    this.queryParam.ws_id = this.record.ws_id;
  },
  mounted() {
    this.getTime();
    // 如果本地存的有标题，就用本地存的标题
    if (localStorage.getItem("CaseFieldView_TitleName")) {
      this.TitleName = localStorage.getItem("CaseFieldView_TitleName");
    }
  },
  watch: {
    showPreviewNum(NV, OV) {
      if (NV == "3x5") {
        this.queryParam.pageSize = 15;
      } else if (NV == "2x5") {
        this.queryParam.pageSize = 10;
      } else if (NV == "2x6") {
        this.queryParam.pageSize = 12;
      } else if (NV == "2x4") {
        this.queryParam.pageSize = 8;
      }
    },
  },
  methods: {
    getTime() {
      this.timer = setInterval(() => {
        let timeDate = new Date();
        let year = timeDate.getFullYear();
        let mounth = timeDate.getMonth() + 1;
        mounth = mounth >= 10 ? mounth : "0" + mounth;
        let day = timeDate.getDate();
        day = day >= 10 ? day : "0" + day;
        let hours = timeDate.getHours();
        hours = hours >= 10 ? hours : "0" + hours;
        let minutes = timeDate.getMinutes();
        minutes = minutes >= 10 ? minutes : "0" + minutes;
        let seconds = timeDate.getSeconds();
        seconds = seconds >= 10 ? seconds : "0" + seconds;
        let week = timeDate.getDay();
        let weekArr = [
          "星期日",
          "星期一",
          "星期二",
          "星期三",
          "星期四",
          "星期五",
          "星期六",
        ];
        this.nowTime = `${year}-${mounth}-${day} ${hours}:${minutes}:${seconds} ${weekArr[week]}`;
      }, 1000);
    },
    clickFun() {
      this.isFullFlag = !this.isFullFlag;
      screenfull.toggle();
    },
    // 双击标题进行编辑
    dbTest() {
      this.isChecked = true;
      this.inputStr = this.TitleName;
    },
    inputStred() {
      this.TitleName = this.inputStr;
      // 编辑标题后保存到本地，下次打开页面还会存在
      localStorage.setItem("CaseFieldView_TitleName", this.TitleName);
      this.isChecked = false;
    },

    // 分页
    onChange(pageNo, size) {
      this.$refs.vSearch.changePageSearch(pageNo);
    },
    changeList(result, page, sum) {
      this.list = result;
      this.current = page;
      this.total = sum;
    },
    NewImageShow_NewImage(value) {
      console.log("showPreview", value);
      this.NewImageShow = value;
    },
    // isshowOnePreview(num) {
    //   this.showOnePreview = !this.showOnePreview;
    // },
    // 多屏选择下拉框
    handleChange(value) {
      console.log(`selected ${value}`);
      if (value == "单屏轮播") {
        // this.showOnePreview = !this.showOnePreview;
        this.showOnePreview = false;
      } else if (value == "2x5分屏") {
        this.showOnePreview = true;
        this.showPreviewNum = "2x5";
      } else if (value == "3x5分屏") {
        this.showOnePreview = true;
        this.showPreviewNum = "3x5";
      } else if (value == "2x6分屏") {
        this.showOnePreview = true;
        this.showPreviewNum = "2x6";
      } else if (value == "2x4分屏") {
        this.showOnePreview = true;
        this.showPreviewNum = "2x4";
      }
    },
    showTimeClick() {
      this.showTime = !this.showTime;
      console.log("1111", this.showTime);
    },
  },
  // 指令
  directives: {
    myfoucs: {
      //自定义myfoucs指令
      update(el, binding) {
        if (binding.value) {
          el.focus();
        }
      },
    },
  },

  destroyed() {
    // 浏览器页面关闭断开视频
    window.removeEventListener("beforeunload", () =>
      this.$refs["LiveBroadcast" + 0].stopVideo()
    );
  },
};
</script>
<style lang="less" scoped>
.v-container {
  background: #0a1621;
  min-width: 1200px;
  height: 100%;
  // height: 98%;
  // overflow: auto;
  // overflow: hidden;
}
.sub-container {
  // min-height: 100%;
  height: 5%;
  margin: 0 10px;
}
.v-title {
  position: relative;
  color: #66ffff;
  height: 75px;
  line-height: 75px;
  font-size: 27px;
  background: url("../../assets/images/visualize/title_inspection.png")
    no-repeat;
  background-position: center center;
  background-size: auto 100%;
  .v-title-left {
    position: absolute;
    left: 30px;
    color: #fff;
    font-size: 14px;
    line-height: 60px;
    width: 20%;
    height: 75px;
  }
  .v-title-right {
    position: absolute;
    right: 30px;
    top: 0;
    color: #fff;
    font-size: 14px;
    line-height: 60px;
    cursor: pointer;
    &:hover {
      color: #66ffff;
    }
  }
  .v-title-right2 {
    display: flex;
    position: absolute;
    right: 7%;
    top: 0;
    color: #fff;
    font-size: 14px;
    line-height: 60px;
    cursor: pointer;
    .v-title-right2-left {
      margin-right: 5px;
    }
  }
  .quanping {
    &:hover {
      color: #66ffff;
    }
  }
  .v-title-right1 {
    position: absolute;
    right: 13%;
    top: 0;
    color: #fff;
    font-size: 16px;
    line-height: 60px;
    cursor: pointer;
    .bofangBtn {
      &:hover {
        color: #66ffff;
      }
    }
  }
}
.sub-buttom {
  height: 91%;
}
.test.editing .edit {
  display: block;
  width: 150px;
  margin-left: 40%;
}

.test.editing .view {
  display: none;
}

.test .edit {
  display: none;
  background-color: #062a49;
}
.Split {
  margin-right: 5px;

  /deep/ .ant-select-arrow {
    color: #fff;
  }
}
/deep/ .ant-select-selection {
  background-color: transparent;
  color: #fff;
  border: none;
}
</style>
